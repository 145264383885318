import { makeStyles } from '@material-ui/core/styles';
const useStyle = makeStyles(theme => ({
  successButton: {
    background: '#50A047',
    '&:hover': {
      background: '#326e2b',
    },
  },
  dangerColor: { color: theme.palette.error.main },
  primaryBg: {
    background: theme.palette.primary.main,
  },
  secondaryBg: {
    background: theme.palette.secondary.main,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  inputsLoading: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  inputsCircularLoading: {
    position: 'absolute',
    right: 15,
    bottom: 0,
    top: 0,
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  showMapButtonImage: {
    position: 'relative',
    height: 100,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$showMapButtonFocusVisible': {
      zIndex: 1,
      '& $showMapButtonImageBackdrop': {
        opacity: 0.35,
      },
      '& $showMapButtonImageMarked': {
        width: 85,
      },
    },
    // "&:active": {
    //   "& $imageMarked": {
    //     width: 100,
    //   },
    // },
  },
  showMapButtonFocusVisible: {},
  showMapButtonImageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },
  showMapButtonImageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
    borderRadius: theme.shape.borderRadius,
  },
  showMapButtonImageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#e9e9e9',
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
    borderRadius: theme.shape.borderRadius,
  },
  showMapButtonImageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,

    fontWeight: 'bold',
  },
  showMapButtonImageMarked: {
    height: 3,
    width: 65,
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    bottom: -2,
    left: '50%',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('width'),
  },
  completedStep: {
    backgroundColor: theme.palette.secondary.main,
  },
  imageDialogRoot: {
    zIndex: `${theme.zIndex.modal + 1} !important`,
  },
}));
export default useStyle;
