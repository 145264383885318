import { useContext } from 'react';

import { IBroadcastContext, ILanguageContext, ILoadingContext } from 'contexts/contexts.interface';
import { BroadcastContext, CurrentSearchContext, LanguageContext, LoadingContext } from 'contexts';
import { IUseCurrentSearchValues } from '@hotelian/hooks/useCurrentSearchValues';

export const useBroadcastContext = () => useContext<IBroadcastContext>(BroadcastContext);
export const useLoadingContext = () => useContext<ILoadingContext>(LoadingContext);
export const useLanguageContext = () => useContext<ILanguageContext>(LanguageContext);

export const useCurrentSearchContext = () => useContext<IUseCurrentSearchValues>(CurrentSearchContext);
