import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageContext } from 'contexts';
import useQuery from './useQuery';

const useReactRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const { locale } = React.useContext(LanguageContext);
  return { location, navigate, query, locale };
};

export default useReactRouter;
