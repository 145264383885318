import React, { useState } from 'react';

import Footer from 'components/Footer';
import MobileTopbar from './common/MobileTopbar';
import MobileAppDrawer from './common/MobileAppDrawer';
import MainErrorBoundary from 'components/ErrorBoundaries/app/MainErrorBoundary';
import B2BAppDesktopErrorBoundary from 'components/ErrorBoundaries/app/B2BAppDesktopErrorBoundary';
import urls from '../constants/urls';

const MobileAppLayout = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="b2b-app-layout">
      <MobileAppDrawer open={open} setOpen={setOpen} />
      <MobileTopbar setOpen={setOpen} logoUrl={urls.app.view} withLanguageSwitcher withCurrencySwitcher />
      <main className="content-container">
        <MainErrorBoundary Layout={B2BAppDesktopErrorBoundary}>{children}</MainErrorBoundary>
      </main>
      <Footer mobileMode />
    </div>
  );
};

export default MobileAppLayout;
