import React, { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

interface IExternalLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {}

const ExternalLink: React.FC<IExternalLinkProps> = ({ ...props }) => {
  return (
    <a rel="noreferrer noopener" {...props}>
      {props.children}
    </a>
  );
};

export default ExternalLink;
