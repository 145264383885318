import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewProfileDetail = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-profile-detail" */ 'views/app/profile/detail')
);

const profileRoutes = [
  {
    id: 'profile/view',
    Component: ViewProfileDetail,
    path: urls.app.profile.view,
  },
];

export default profileRoutes;
