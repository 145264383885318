import React, { Fragment, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';

import useInterval from '@hotelian/hooks/useInterval';
import { captchaExpirationStorage } from '@hotelian/helpers/storageHelpers';
import { captchaExpirationTimeout } from '@hotelian/constants/defaultValues';
import General from '@hotelian/utils/models/General';

const Captcha = ({ setToken, ...props }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [gettingCaptchaloading, setGettingCaptchaLoading] = useState(false);

  const getNewCaptcha = async () => {
    setGettingCaptchaLoading(true);

    try {
      const res = await General.getCaptcha();

      setToken(res?.token);
      setImageUrl(res?.imageUrl);
      return res;
    } catch (_e) {
      return null;
    } finally {
      setGettingCaptchaLoading(false);
    }
  };

  useInterval({
    callbackFn: getNewCaptcha,
    timeout: { minutes: captchaExpirationTimeout },
    storage: captchaExpirationStorage,
    options: { runAtFirstRender: true },
  });

  const imgCmp = (
    <Fragment>
      {imageUrl ? <img src={imageUrl} alt="captcha" style={{ width: 80 }} className="h-auto" /> : <div />}
    </Fragment>
  );
  return (
    <div className="b2b-captcha-ctn" {...props}>
      <IconButton color="primary" size="small" disabled={gettingCaptchaloading} onClick={getNewCaptcha}>
        <FontAwesomeIcon icon={faSync} />
      </IconButton>
      {imgCmp}
    </div>
  );
};

export default Captcha;
