import React, { useEffect } from 'react';

import { CurrentSearchContext as CurrentSearchContextProvider } from './';
import { Children } from '@hotelian/config/types';
import useCurrentSearchValues from '@hotelian/hooks/useCurrentSearchValues';
import usePrevious from '@hotelian/hooks/usePrevious';
import { useLanguageContext } from 'utils/hooks/contextHooks';

interface ICurrentSearchContext {
  children: Children;
}

const CurrentSearchContext: React.FC<ICurrentSearchContext> = ({ children }) => {
  const currentSearchHelpers = useCurrentSearchValues();

  const { locale } = useLanguageContext();

  const prevLocale = usePrevious(locale);

  useEffect(() => {
    if (prevLocale !== locale && prevLocale !== undefined) {
      // remove current search in case of change language (to show search values in user language preference)
      currentSearchHelpers.removeCurrentSearch();
    }
  }, [locale]);

  return (
    <CurrentSearchContextProvider.Provider value={currentSearchHelpers}>
      {children}
    </CurrentSearchContextProvider.Provider>
  );
};

export default CurrentSearchContext;
