import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';
import reserveRoutes from 'config/routes/appRoutes/hotelRoutes/reserveRoutes';

const ViewSearch = lazyWithRetry(() => import(/* webpackChunkName: "views-search" */ 'views/app/hotel/search'));
const ViewHotel = lazyWithRetry(() => import(/* webpackChunkName: "views-hotel-page" */ 'views/app/hotel/hotel'));

const hotelRoutes = [
  {
    id: 'hotel/search',
    Component: ViewSearch,
    path: urls.app.hotel.search,
  },
  {
    id: 'hotel/view',
    Component: ViewHotel,
    path: urls.app.hotel.view,
  },
  ...reserveRoutes,
];

export default hotelRoutes;
