const contact = {
  london: {
    address: '17 Hanover Square, London, Greater London, W1S 1BN, United Kingdom',
    phones: ['+44 203 807 4836'],
  },
  dubai: {
    address: '702 , Regal Tower, Downtown Burj, Khalifa Business Bay, Dubai, UAE',
    phones: ['+971 4 401 3535'],
    faxes: ['+971 4 296 3412'],
  },
  istanbul: { address: 'No:2201, Quasar Tower, Sisli, Istanbul, Turkey', phones: ['+90 216 771 1001'] },
  iran: {
    address:
      'Kish Island: Unit 309, 2nd floor, Barcode Building, Eqtesad Alley 1, Iran BLVD. , Industrial Phase 2, Kish',
    phones: ['+98 07691012812'],
  },
  emails: [
    { email: 'Info@hotelian.com', main: true },
    {
      email: 'Tech@hotelian.com',
      description: 'general.contact-us.form.email-technical',
      tech: true,
    },
  ],
};

export default contact;
