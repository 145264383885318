import userRoutes from './userRoutes';
import generalRoutes from './generalRoutes';
import fallbackRoutes from './fallbackRoutes';

export const redirectRoutes = [];

export const freeRoutes = userRoutes.routes
  .map(route => route.path)
  .concat(
    generalRoutes.routes.map(route => route.path),
    fallbackRoutes.routes.map(route => route.path)
  );
