import React from 'react';

import DesktopGeneralLayout from './DesktopGeneralLayout';
import MobileGeneralLayout from './MobileGeneralLayout';

const GeneralLayout = ({ children, mobileMode, ...props }) => {
  if (mobileMode) {
    return <MobileGeneralLayout {...props}>{children}</MobileGeneralLayout>;
  }
  return <DesktopGeneralLayout {...props}>{children}</DesktopGeneralLayout>;
};

export default GeneralLayout;
