import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewTicketList = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-ticket-list" */ 'views/app/support/list')
);
const ViewNewTicket = lazyWithRetry(() => import(/* webpackChunkName: "views-new-ticket" */ 'views/app/support/new'));
const ReplyNewTicket = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-new-ticket" */ 'views/app/support/reply')
);

const supportRoutes = [
  {
    id: 'support/list',
    Component: ViewTicketList,
    path: urls.app.support.list,
  },
  {
    id: 'support/reply',
    Component: ReplyNewTicket,
    path: urls.app.support.reply,
  },
  {
    id: 'support/new',
    Component: ViewNewTicket,
    path: urls.app.support.new,
  },
];

export default supportRoutes;
