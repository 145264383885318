import { AxiosError } from 'axios';

import { IDefaultException } from '@hotelian/utils/ErrorHandler';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import BroadcastStrategy from '@hotelian/utils/Strategy/BroadcastStrategy';
import { IUIActionNames } from '../../../interfaces';

const observer = BroadcastStrategy.getInstance<IUIActionNames>();

const defaultNotFound: IDefaultException = (error: AxiosError) => {
  const { message } = BackendErrorExtractor.handle(error);
  if (typeof window !== 'undefined' && message) {
    window.openSnackbar(message, 'error');
  }
  observer.fire({ name: 'BACKEND_ITEM_NOT_FOUND', payload: { message } }, { crossTab: false });
};

export default defaultNotFound;
