import React, { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { SnackbarVariant } from '@hotelian/config/interfaces';

const defaultDuration = 6000;
const maxDuration = 15 * 1000;
const durationPerLetter = 60;

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarComponent = () => {
  const [open, setOpen] = useState<string | boolean | null | React.ReactElement>(null);
  const [severity, setSeverity] = useState<SnackbarVariant>('success');

  const handleClick = (content: string | React.ReactElement, type: SnackbarVariant) => {
    setSeverity(type);
    setOpen(content);
  };

  const getDuration = () => {
    if (open) {
      if (typeof open === 'string') {
        const stringLength = open.length;
        if (stringLength > 100) {
          return stringLength * durationPerLetter < maxDuration ? stringLength * durationPerLetter : maxDuration;
        } else {
          return defaultDuration;
        }
      } else {
        return defaultDuration;
      }
    } else {
      return defaultDuration;
    }
  };

  const duration = getDuration();
  const handleClose = (event: React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(null);
  };

  const handleCloseAlert = (_event: React.SyntheticEvent) => {
    setOpen(null);
  };

  useEffect(() => {
    window.openSnackbar = (content, type = 'success') => {
      handleClick(content, type);
    };
  }, []);

  return (
    <Snackbar open={Boolean(open)} autoHideDuration={duration} onClose={handleClose}>
      <Alert
        onClick={(e: React.UIEvent) => {
          e.stopPropagation();
        }}
        onClose={handleCloseAlert}
        severity={severity}
      >
        {open}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
