import locales from 'locales';
import { userLocaleStorage } from '@hotelian/helpers/storageHelpers';
import { Locale } from '@hotelian/config/interfaces';
const getCurrentLocale = () => {
  const userLocale = userLocaleStorage.get();

  const isValidLocale = Boolean(userLocale) && locales.some(l => l.locale === userLocale);
  return locales.find(l => {
    if (!isValidLocale) {
      return l.default;
    }
    return l.locale === userLocale;
  }) as Locale;
};
export default getCurrentLocale;
