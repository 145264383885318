import { AxiosError } from 'axios';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';

const ExceptionOf500 = (error: AxiosError) => {
  const { message } = BackendErrorExtractor.handle(error);
  if (typeof window !== 'undefined' && message) {
    window.openSnackbar(message, 'error');
  }
};

export default ExceptionOf500;
