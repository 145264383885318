import React, { useEffect, useState } from 'react';

import { CurrencyContext } from '.';
import getCurrency from 'helpers/getCurrency';
import usePageVisibility from '@hotelian/hooks/usePageVisibility';
import { currencyStorage } from '@hotelian/helpers/storageHelpers';
import { Instance } from 'utils/AxiosInstance';

const CurrencyContextProvider = ({ children }) => {
  const [currency, setCurrency] = useState(getCurrency());
  const isVisible = usePageVisibility();

  const handleChangeCurrency = () => {
    const currency = getCurrency();
    setCurrency(currency);
    Instance.setCurrency(currency?.value);
  };
  const changeCurrency = async (value, toggleModal = () => {}) => {
    await currencyStorage.set(value?.toUpperCase());
    handleChangeCurrency();
    toggleModal();
  };

  useEffect(() => {
    if (currency !== getCurrency() && isVisible) {
      handleChangeCurrency();
    }
  }, [isVisible]);

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
        changeCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContextProvider;
