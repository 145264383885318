interface ICaptchaBackendResponse {
  token: string;
  image_url: string;
}

class CaptchaModel {
  token!: string;
  imageUrl!: string;

  static create(values: ICaptchaBackendResponse) {
    const instance = new CaptchaModel();
    instance.token = values.token;
    instance.imageUrl = values.image_url;
    return instance;
  }
}

export default CaptchaModel;
