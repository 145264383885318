import { useEffect, useState } from 'react';

import { accessTokenStorage, baseDataStorage } from '@hotelian/helpers/storageHelpers';
import GeneralQueries from '@hotelian/utils/models/General/queries';

interface IStringKeyValues {
  [key: string]: string;
}

interface IEnvironment {
  debug: boolean;
  environment: string;
  last_updated: Date;
  version: string;
}

interface ILateCheckin {
  [key: number]: string;
}

export interface IUseBaseDataContextMain {
  'base-url-api': string;
  'base-url-hotel': string;
  'cancellation-reason': IStringKeyValues;
  environment: IEnvironment;
  languages: IStringKeyValues;
  'late-checkin': ILateCheckin;
  'ticket-department': IStringKeyValues;
  'ticket-priority': IStringKeyValues;
}
export interface IUseBaseDataContext {
  baseData: IUseBaseDataContextMain;
  getBaseData: () => Promise<any>;
  locale: string;
}

const useBaseDataContext = (withAuth: boolean | undefined, user: any, locale: string): IUseBaseDataContext => {
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);

  useEffect(() => {
    if (withAuth && !user) {
      setIsQueryEnabled(false);
    } else if (!withAuth || (withAuth && user && accessTokenStorage.get())) {
      setIsQueryEnabled(true);
    }
  }, [withAuth, user]);

  const { data: baseData, refetch } = GeneralQueries.useBaseData(locale, {
    queryOptions: {
      retry: false,
      enabled: isQueryEnabled,
      onSuccess: data => {
        baseDataStorage.set(data);
      },
    },
  });

  const getBaseData = async () => {
    await refetch();
  };

  return { baseData, getBaseData, locale };
};

export default useBaseDataContext;
