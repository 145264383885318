import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  password: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  captcha_value: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .length(5, <FormattedMessage id="validation.invalid-captcha" />),
});

export const corporateLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage id="validation.email" />)
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  password: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  captcha_value: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .length(5, <FormattedMessage id="validation.invalid-captcha" />),
});
