import { AxiosError } from 'axios';
import { IDefaultException } from '@hotelian/utils/ErrorHandler';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';

const unknownErrorHandler: IDefaultException = (error: AxiosError) => {
  const { message } = BackendErrorExtractor.handle(error);
  if (typeof window !== 'undefined' && message) {
    window.openSnackbar(message, 'error');
  }
};

export default unknownErrorHandler;
