import { FormattedMessage } from 'react-intl';

export const banStatuses = [
  { value: 1, label: <FormattedMessage id="all.banned" /> },
  { value: 0, label: <FormattedMessage id="all.not-banned" /> },
];

export const levels = [
  {
    value: 'bronze',
    color: '#cd7f32',
    label: <FormattedMessage id="agency.level.bronze" />,
    icon: '/images/user/bronze.svg',
  },
  {
    value: 'silver',
    color: '#C0C0C0',
    label: <FormattedMessage id="agency.level.silver" />,
    icon: '/images/user/silver.svg',
  },
  {
    value: 'gold',
    color: '#ffd700',
    label: <FormattedMessage id="agency.level.gold" />,
    icon: '/images/user/gold.svg',
  },
  {
    value: 'platinum',
    color: '#E5E4E2',
    label: <FormattedMessage id="agency.level.platinum" />,
    icon: '/images/user/platinum.svg',
  },
];
