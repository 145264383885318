import React, { useContext, useEffect, useState } from 'react';

import { AuthContext, BaseDataContext, LanguageContext } from '.';
import useBaseDataContext from '@hotelian/hooks/useBaseDataContext';
import { Children } from '@hotelian/config/types';

interface IBaseDataOption {
  value: string;
  label: string;
  filterLabel: string;
  querySearch: {
    [key: string]: string;
  };
}

export type IBaseDataOptions = IBaseDataOption[];

const BaseDataContextProvider: React.FC<{ children: Children }> = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { locale } = useContext(LanguageContext);

  const [departmentOptions, setDepartmentOptions] = useState<IBaseDataOptions>([]);
  const [priorityOptions, setPriorityOptions] = useState<IBaseDataOptions>([]);

  const props = useBaseDataContext(true, user, locale);

  const { baseData } = props;
  useEffect(() => {
    if (baseData) {
      const departmentObj = baseData?.['ticket-department'];
      const priorityObj = baseData?.['ticket-priority'];

      if (priorityObj && departmentObj) {
        const priorityToSet: IBaseDataOptions = [];
        const departmentToSet: IBaseDataOptions = [];

        Object.entries(priorityObj).map(([key, value]) =>
          priorityToSet.push({
            value: key,
            label: value,
            filterLabel: value,
            querySearch: { 'filter[priority]': value },
          })
        );
        Object.entries(departmentObj).map(([key, value]) =>
          departmentToSet.push({
            value: key,
            label: value,
            filterLabel: value,
            querySearch: { 'filter[department]': value },
          })
        );

        setPriorityOptions(priorityToSet);
        setDepartmentOptions(departmentToSet);
      }
    }
  }, [baseData]);

  return (
    <BaseDataContext.Provider value={{ ...props, departmentOptions, priorityOptions }}>
      {children}
    </BaseDataContext.Provider>
  );
};

export default BaseDataContextProvider;
