import React from 'react';

import LanguageContext from './LanguageContext';
import LoadingContext from './LoadingContext';
import CurrencyContext from './CurrencyContext';
import UIContext from './UIContext';
import AuthContext from './AuthContext';
import DraftContext from './DraftContext';
import BaseDataContext from './BaseDataContext';
import BroadcastContext from './BroadcastContext';
import CurrentSearchContext from './CurrentSearchContext';

const ContextsProvider = ({ children }) => {
  return (
    <BroadcastContext>
      <LoadingContext>
        <LanguageContext>
          <CurrencyContext>
            <UIContext>
              <AuthContext>
                <BaseDataContext>
                  <DraftContext>
                    <CurrentSearchContext>{children}</CurrentSearchContext>
                  </DraftContext>
                </BaseDataContext>
              </AuthContext>
            </UIContext>
          </CurrencyContext>
        </LanguageContext>
      </LoadingContext>
    </BroadcastContext>
  );
};

export default ContextsProvider;
