import { useEffect, useState } from 'react';
import cookies from 'js-cookie';

import { arrayMover, extractValidDestinationDrafts } from '@hotelian/utils';
import {
  accessTokenStorage,
  destinationDraftStorage,
  nationalityDraftStorage,
  userCountryCodeStorage,
} from '@hotelian/helpers/storageHelpers';
import General from '@hotelian/utils/models/General';
import DestinationRecommendationHit from '@hotelian/utils/models/General/DestinationRecommendation/DestinationRecommendationHit';
import Nationality from '@hotelian/utils/models/Nationality';

const version = '1.2.3';

const useDraftContext = (withAuth?: boolean, user?: any, options?: { withNationality: boolean; language?: string }) => {
  const [destinationDraft, setDestinationDraft] = useState<DestinationRecommendationHit[] | null>(null);
  const [nationalityDraft, setNationalityDraft] = useState<Nationality[] | null>(null);

  const getDestinationRecommendation = async () => {
    const countryCode = cookies.get(userCountryCodeStorage.storageKey);

    try {
      const response = await General.getDestinationRecommendation({
        nationality: Boolean(countryCode) ? countryCode : undefined,
      });
      await destinationDraftStorage.set({ items: response.hits, v: version });
      setDestinationDraft(response.hits);
      // nationality
      const nationality = [{ ...(response.nationality ?? {}) }];
      await nationalityDraftStorage.set({ items: nationality, v: version });
      setNationalityDraft(nationality);
    } catch (_e) {
      return null;
    }
  };

  useEffect(() => {
    setNationalityDraft([]);
    nationalityDraftStorage.set({ v: version, items: [] });
  }, [options?.language]);

  const saveDrafts = (values: any) => {
    // ===========================
    // === nationalities draft ===
    // ===========================
    if (options?.withNationality) {
      let nationality = null;
      // empty draft
      if (!Boolean(nationalityDraftStorage.get()?.items?.length)) {
        nationality = [values.nationality];
      }
      // have draft
      else {
        // repetitious nationality
        const elementIndex = nationalityDraft?.findIndex(el => el.code === values.nationality?.code);
        if (typeof elementIndex === 'number' && elementIndex >= 0 && nationalityDraft) {
          nationality = arrayMover([...nationalityDraft], elementIndex, 0);
        }
        // new nationality
        else {
          nationality = nationalityDraft ? nationalityDraft.concat([values.nationality]) : [];
          nationality = arrayMover([...nationality], nationality.length - 1, 0);
          nationality = nationality.splice(0, 5);
        }
      }
      nationalityDraftStorage.set({ items: nationality, v: version });
      setNationalityDraft(nationality);
    }
    // =========================
    // === destination draft ===
    // =========================
    // ignore google results
    if (values.destination.dest_type === 'google') {
      return;
    }
    let destination = null;
    // empty draft
    if (!Boolean(destinationDraftStorage.get()?.items?.length)) {
      destination = [values.destination];
    }
    // have draft
    else {
      const elementIndex = destinationDraft?.findIndex(el => el.dest_code == values.destination?.dest_code);
      // repetitious destination
      if (elementIndex && elementIndex >= 0 && destinationDraft) {
        destination = arrayMover([...destinationDraft], elementIndex, 0);
      }
      // new destination
      else {
        if (destinationDraft) {
          destination = destinationDraft.concat([values.destination]);
          destination = arrayMover([...destination], destination.length - 1, 0);
          destination = destination.splice(0, 5);
        }
      }
    }
    const destinationsToSet = extractValidDestinationDrafts({ items: destination, v: version });
    destinationDraftStorage.set(destinationsToSet);
    setDestinationDraft(destinationsToSet?.items);
  };

  const getRecommendationHandler = () => {
    if (withAuth) {
      if (Boolean(user) && accessTokenStorage.get()) {
        getDestinationRecommendation();
      }
    } else {
      getDestinationRecommendation();
    }
  };

  useEffect(() => {
    const savedNationalityDraft = nationalityDraftStorage?.get();
    const savedDestinationDraft = destinationDraftStorage?.get();
    if (
      (typeof savedNationalityDraft?.v === 'string' && savedNationalityDraft?.v !== version) ||
      (typeof savedDestinationDraft?.v === 'string' && savedDestinationDraft?.v !== version)
    ) {
      getRecommendationHandler();
    } else {
      if (savedNationalityDraft?.items?.length) {
        const nationalityDraftsToSet: any = [];
        savedNationalityDraft.items.map((draftItem: any) => {
          const isDraftEmpty = Object.entries(draftItem).filter((_key, value) => Boolean(value)).length === 0;
          const hasAllRequiredFields = Object.entries(draftItem)?.length === 3;
          const allFieldsValid = Object.entries(draftItem)
            .map(([_key, value]) => value)
            .every(Boolean);
          const hasValidLanguage = options?.language ? draftItem?.name?.language === options.language : true;
          if (
            allFieldsValid &&
            !isDraftEmpty &&
            hasAllRequiredFields &&
            hasValidLanguage &&
            !nationalityDraftsToSet?.find((el: any) => el.code === draftItem.code)
          ) {
            nationalityDraftsToSet.push(draftItem);
          }
        });
        setNationalityDraft(nationalityDraftsToSet);
        nationalityDraftStorage.set({ items: nationalityDraftsToSet, v: version });
      }
      if (savedDestinationDraft?.items?.length) {
        const validDestinations = extractValidDestinationDrafts(savedDestinationDraft);
        destinationDraftStorage.set(validDestinations);
        setDestinationDraft(validDestinations.items);
      } else {
        getRecommendationHandler();
      }
    }
  }, [withAuth, user]);

  return {
    saveDrafts,
    destinationDraft,
    setDestinationDraft,
    nationalityDraft,
    setNationalityDraft,
    getDestinationRecommendation,
  };
};

export default useDraftContext;
