import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Footer from 'components/Footer';
import { AuthContext } from 'contexts';
import DesktopGeneralNav from './common/DesktopGeneralNav';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { isMultiLanguage } from 'constants/default-values';
import urls from '../constants/urls';

const DesktopGeneralLayout = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <div className="b2b-general-layout">
      <header className="b2b-general-layout-header">
        <div>
          <div className="content__wrapper h-100">
            <div className="h-100 d-flex justify-content-between align-items-center flex-nowrap">
              <div style={{ zIndex: 1 }}>
                <DesktopGeneralNav />
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <div className={`${isMultiLanguage ? 'mr-2' : ''}`}>
                  <Link to={isLoggedIn ? urls.app.view : urls.user.login}>
                    <Button className="b2b-general-layout-login-button px-4 font-weight-bold" size="large">
                      {isLoggedIn ? <FormattedMessage id="all.dashboard" /> : <FormattedMessage id="all.login" />}
                    </Button>
                  </Link>
                </div>
                <div>
                  <LanguageSwitcher size="large" className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="content-container">
        <div className="h-100">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default DesktopGeneralLayout;
