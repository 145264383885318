import React, { useEffect } from 'react';

import { Children } from '@hotelian/config/types';
import { BroadcastContext } from './';
import WindowObserver from '@hotelian/utils/Observers/Window.observer';
import { Instance } from 'utils/AxiosInstance';
import { getToken } from '../utils';
import BroadcastStrategy from '@hotelian/utils/Strategy/BroadcastStrategy';
import { IUIActionNames } from '../utils/interfaces';

interface IBroadcastContext {
  children: Children;
}
const observer = BroadcastStrategy.getInstance<IUIActionNames>();

const BroadcastContextProvider: React.FC<IBroadcastContext> = ({ children }) => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(`/service-worker.js`)
        .then(() => {
          navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations.length === 0) {
              observer.setStrategy(WindowObserver.getInstance());
            }
          });
        })
        .finally(() => {
          observer.registerListener();
        });
    } else {
      observer.setStrategy(WindowObserver.getInstance());
    }
    observer.subscribe({
      name: 'RETRY_FAILED_REQUESTS',
      callback: () => {
        Instance.retryFailedRequests(getToken());
      },
    });
  }, []);

  return <BroadcastContext.Provider value={{ observer }}>{children}</BroadcastContext.Provider>;
};

export default BroadcastContextProvider;
