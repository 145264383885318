import { createElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const selectDefaultValue = 'all';

export const selectDefaultItem = Object.freeze({
  value: selectDefaultValue,
  default: true,
  title: createElement(FormattedMessage, { id: 'all.all' }),
  label: createElement(FormattedMessage, { id: 'all.all' }),
  filterLabel: createElement(FormattedMessage, { id: 'all.all' }),
});
