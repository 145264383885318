import urls from 'constants/urls';
import { lazyWithRetry } from 'utils';

const ViewLogin = lazyWithRetry(() => import(/* webpackChunkName: "views-contact-us" */ 'views/user/login'));
const ViewPersonate = lazyWithRetry(() => import(/* webpackChunkName: "views-contact-us" */ 'views/user/personate'));

const userRoutes = {
  mainPrefix: urls.user.mainPrefix,
  Layout: undefined,
  routes: [
    { id: 'user/login', Component: ViewLogin, path: urls.user.login },
    { id: 'user/personate', Component: ViewPersonate, path: urls.user.personate },
  ],
};

export default userRoutes;
