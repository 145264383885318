// backend apis
const api = {
  main: {
    main: '/general/generic-data',
    cityDestination: '/city-destination',
  },
  magazine: { main: '/magazine' },
  voucherValidation: { main: '/general/voucher-validation' },
  profile: {
    loginByGoogle: '/user/login-by-google',
    signupByEmail: '/user/register_email',
    signupByPhone: '/user/register_phone',
    resendCode: '/user/verify/resend',
    registerPhoneContinuation: '/user/register_phone_continuation',
    validate: '/user/verify/validate',
    verify: '/user/verify',
    loginByEmail: '/user/login/email',
    loginByPhone: '/user/login/phone',
    logout: '/user',
    userDetail: '/user',
    updateProfile: '/user',
    setProfile: '/user/set_profile_pic',
    resetPassword: '/user/password',
    getForgetPasswordToken: '/user/reset_password/request_token',
    resetForgetPassword: '/user/reset_password/reset',
    setLanguage: '/user/set_language',
    setCurrency: '/user/set_currency',
  },
  autoComplete: {
    nationality: '/auto-complete/nationality',
    destination: '/auto-complete/destination',
    hotel: '/auto-complete/hotel',
  },
  hotels: {
    main: '/hotel',
    fullOptionCheck: '/hotel/full-option-check',
    hotelsList: '/hotel/list',
    countries: '/country',
    states: '/state',
    cities: '/city',
    availability: '/hotel/availability',
    search: '/hotel/search',
    optionCheck: '/hotel/option-check',
    landmark: '/hotel/nearby-landmark',
    airport: '/hotel/nearby-airport',
    destination: '/hotel/in-destination',
  },
  review: {
    newReview: '/review/new-review',
    editReview: '/review/edit-review',
    hotelReview: '/review/hotel-reviews',
    react: '/review/react',
    userReviews: '/review/user-reviews',
  },
  support: {
    main: '/user/ticket',
    newTicket: '/user/ticket/new-ticket',
    ticketAttachment: '/user/ticket/ticket-attachment',
    thread: '/user/ticket/thread',
    replyTicket: '/user/ticket/reply',
    replyTicketAttachment: '/user/ticket/reply-ticket-attachment',
    closeTicket: '/user/ticket/close',
  },
  invoices: { list: '/user/invoice' },
  reservation: {
    occupants: '/reservation/hotel/occupants',
    summary: '/reservation/summary',
    transaction: '/reservation/transaction',
    redirect: '/user/transaction/transaction-redirect',
    coupon: '/invoice/apply-coupon',
    verifyTransaction: '/user/transaction/verify-transaction',
    book: '/reservation/book',
    list: '/user/hotel/reserve',
    details: '/reservation/details',
    cancel: '/reservation/cancel',
  },
  newsletter: {
    subscribe: '/newsletter/subscribe',
    unsubscribe: '/newsletter/unsubscribe',
  },
  captcha: {
    new: '/captcha/new',
  },
  general: {
    destinationRecommendation: '/general/destination-recommendation',
    translate: '/general/translate',
    getTypes: '/general/types',
    termsOfUse: '/general/terms-of-use',
    privacyPolicy: '/general/privacy-policy',
    faq: '/general/faq',
    contactUs: '/contact/contact',
    decideUserStatus: '/general/user-preferences',
  },
  b2b: {
    user: {
      login: '/user/login/username',
      userDetail: '/user',
      logout: '/user',
      transaction: '/user/transaction',
      redirectTransaction: '/user/transaction/transaction-redirect',
      corporateLogin: '/corporate/login',
    },
    agency: {
      main: '/agency',
      setProfile: '/agency/set-logo',
      user: '/agency/user',
      viewUser: '/agency/view-user',
      createUser: '/agency/create-user',
      updateUser: '/agency/update-user',
      webServices: {
        info: '/agency/webservice/info',
        trustedIp: '/agency/webservice/trusted-ip',
      },
    },
    general: {
      registration: '/general/registration',
      privacyPolicy: '/general/privacy-policy',
      contactUs: '/contact/contact',
      termsOfUse: '/general/terms-of-use',
      errorReport: '/general/error',
      corporate: {
        register: '/corporate/register',
        verify: '/corporate/register/verify',
      },
    },
  },
};

export default api;
