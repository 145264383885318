import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewTransactionList = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-transaction-list" */ 'views/app/transaction/list')
);

const transactionRoutes = [
  {
    id: 'transaction/list',
    Component: ViewTransactionList,
    path: urls.app.transaction.list,
  },
];

export default transactionRoutes;
