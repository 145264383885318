import React, { Fragment, useEffect, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { mobileDetection } from '@hotelian/utils/mobileDetection';
import ProtectedRoute from 'components/ProtectedRoute';
import urls from 'constants/urls';

const device = mobileDetection();
const mobileMode = !device.isDesktop;
const body = document.querySelector('body');

const handleRemovePrefix = (prefix, path) => path.replace(`${prefix.replace('*', '')}`, '');

const RoutesMapper = ({ routeObject }) => {
  const { routes, mainPrefix } = routeObject;

  return (
    <Suspense fallback={<div className="loading" />}>
      <Routes>
        {routes.map(({ Component, path, id, perms }) => {
          return (
            <Fragment key={id}>
              <Route
                element={
                  <ProtectedRoute perms={perms ?? []}>
                    <Component mobileMode={mobileMode} />
                  </ProtectedRoute>
                }
                path={handleRemovePrefix(mainPrefix, path)}
              />
            </Fragment>
          );
        })}
        <Route path="*" element={<Navigate replace to={urls.fallback.error404} />} />
      </Routes>
    </Suspense>
  );
};

const addDimensionClass = () => {
  body.classList.remove('mobile');
  body.classList.remove('desktop');
  if (mobileMode) {
    body.classList.add('mobile');
  } else {
    body.classList.add('desktop');
  }
};
addDimensionClass();

const RouteRenderer = ({ routeObject }) => {
  useEffect(() => {
    addDimensionClass();
    return () => {
      body.classList.remove('desktop');
      body.classList.remove('mobile');
    };
  }, []);

  const { Layout } = routeObject;

  if (Layout) {
    return (
      <Layout mobileMode={mobileMode}>
        <RoutesMapper routeObject={routeObject} />
      </Layout>
    );
  } else {
    return <RoutesMapper routeObject={routeObject} />;
  }
};

export default RouteRenderer;
