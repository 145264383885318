import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { lazyWithRetry } from 'utils';

const View500Error = lazyWithRetry(() => import(/* webpackChunkName: "views-error" */ 'views/error/Custom500'));

const MainErrorHandler = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={View500Error}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default MainErrorHandler;
