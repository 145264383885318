import { agencyEditPerm } from 'constants/permissions';
import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewServicesDetail = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-services-list" */ 'views/app/services/details')
);

const servicesRoutes = [
  {
    id: 'services/list',
    Component: ViewServicesDetail,
    path: urls.app.services.list,
    perms: [agencyEditPerm],
  },
];

export default servicesRoutes;
