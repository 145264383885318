// @ts-ignore
import { ltrTheme } from '@hotelian/config/ThemeProvider';
const hotelianLogger = (color: 'primary' | 'secondary' = 'primary') => {
  let mainColor;
  if (color === 'secondary') {
    mainColor = ltrTheme.palette.secondary.main;
  } else {
    mainColor = ltrTheme.palette.primary.main;
  }
  console.log(
    `%c
  
  
  
   ██╗  ██╗ ██████╗ ████████╗███████╗██╗     ██╗ █████╗ ███╗   ██╗
   ██║  ██║██╔═══██╗╚══██╔══╝██╔════╝██║     ██║██╔══██╗████╗  ██║
   ███████║██║   ██║   ██║   █████╗  ██║     ██║███████║██╔██╗ ██║
   ██╔══██║██║   ██║   ██║   ██╔══╝  ██║     ██║██╔══██║██║╚██╗██║
   ██║  ██║╚██████╔╝   ██║   ███████╗███████╗██║██║  ██║██║ ╚████║
   ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚══════╝╚══════╝╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝.com
  
  
  %c🧳 The world is yours just by a click.%c
  
  
  
  `,
    `color: ${mainColor};`,
    `color: #fff;
    background: ${mainColor};
    border-radius:5px;font-size:1.33rem;
    padding:5px;`,
    ''
  );
};
export default hotelianLogger;
