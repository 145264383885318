import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import ListHandler from './ListHandler';
import { BackendError } from '@hotelian/config/types';

class ResponseHandler {
  static async handle<T>(
    request: (() => Promise<Api>) | Promise<Api>,
    Model: { create: (values: any) => T },
    { onSuccess, onError }: { onSuccess?: (res: any) => any; onError?: (err: any) => any } = {}
  ): Promise<T> {
    if (!Model.create)
      throw new Error('Entities that are using ResponseHandler (handle method) must have static create method');
    return new Promise(async (resolve, reject) => {
      let response;
      if (request instanceof Function) {
        response = await request();
      } else {
        response = await request;
      }

      if (response.isSuccess) {
        if (onSuccess) onSuccess(response.response);
        return resolve(Model.create(response.response as T) as T);
      }
      if (onError) {
        onError(response.errors);
      }
      return reject(response.errors);
    });
  }

  static async handleList<T>(request: Promise<Api> | (() => Promise<Api>), Model: { create: (values: any) => T }) {
    if (!Model.create)
      throw new Error('Entities that are using ResponseHandler (handleList method) must have static create method');
    return new Promise(async (resolve, reject) => {
      let response;
      if (request instanceof Function) {
        response = await request();
      } else {
        response = await request;
      }
      if (response && response?.isSuccess) return resolve(ListHandler.handle(response?.response, Model));
      return reject(response?.errors);
    });
  }

  static async handleSimpleList<T>(
    request: Promise<Api> | (() => Promise<Api>),
    Model: { create: (values: any) => T }
  ): Promise<T[]> {
    if (!Model.create) throw new Error('Entities that are using ListHandler facade must have static create method');
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.freeHandling<T[]>(request);
        return resolve(ListHandler.handleSimpleArray(res, Model)) as unknown as T[];
      } catch (e) {
        return reject(e);
      }
    });
  }

  static async freeHandling<T = any>(
    request: Promise<Api> | (() => Promise<Api>),
    onSuccess: (res: T) => any = res => res,
    onError: (err: BackendError) => any = err => err
  ): Promise<T> {
    return new Promise(async (resolve, reject) => {
      let response;
      if (request instanceof Function) {
        response = await request();
      } else {
        response = await request;
      }
      if (response.isSuccess) {
        resolve(onSuccess(response.response) as T);
        return onSuccess(response.response);
      }
      if (response.errors) {
        onError(response.errors);
      }
      reject(response.errors);
      return response.errors;
    });
  }
}

export default ResponseHandler;
