import React, { Fragment, useContext } from 'react';
import { AuthContext } from 'contexts';
import { isObject } from '@hotelian/utils';

/**
 * application traffic light
 * @author HotelianCom
 * @param {Array} perms list of permissions
 * @returns {Boolean} if returns true => have permission (green light), else => red light
 */
export const usePermission = perms => {
  if (!Array.isArray(perms)) {
    throw Error('Type of perms must be Array');
  }
  const { user } = useContext(AuthContext);
  const permissions = isObject(user?.permissions)
    ? Object.entries(user?.permissions)
        .filter(el => el[1])
        .map(([key, _value]) => key)
    : null;

  if (perms.length === 0) {
    return true;
  }
  return perms.some(perm => permissions.includes(perm));
};

export const WithPermission = ({ perms, children }) => {
  const permission = usePermission(perms);
  return <Fragment>{permission ? children : null}</Fragment>;
};
