import React, { Fragment } from 'react';
const footerImages = [
  { src: 'footer__visa.png', alt: 'visa' },
  { src: 'footer__vf-by-visa.png', alt: 'verified by visa' },
  { src: 'footer__mcard_sc.png', alt: 'master card secure code' },
  { src: 'footer__mcard.png', alt: 'master card' },
  { src: 'footer__sectigo.png', alt: 'secured by sectigo', url: '#' },
];

const ItemImage = ({ el }) => (
  <img className="icon-ratio--lg ml-2" src={'/images/footer-images/' + el.src} alt={el.alt} />
);
const CreditIcons = () => {
  return (
    <Fragment>
      {footerImages.map((el, i) => {
        if (el.url) {
          return (
            <a key={i} href={el.url}>
              <ItemImage el={el} />
            </a>
          );
        }
        return <ItemImage key={i} el={el} />;
      })}
    </Fragment>
  );
};

export default CreditIcons;
