import React from 'react';
import logo from '@hotelian/assets/hotelian-logo-topbar.svg';
import { gb2bWebsiteUrl } from '@hotelian/constants/defaultValues';
import { generalDesktopNavMenu } from 'constants/navMenu';
import { Link } from 'react-router-dom';
import { isCorporate } from 'helpers';
import urls from 'constants/urls';

const DesktopGeneralNav = ({ withoutLogo }) => {
  return (
    <nav className="b2b-desktop-nav">
      <ul className="list-unstyled p-0 m-0 d-flex align-items-center align-items-center">
        {withoutLogo ? null : (
          <li className="mr-4">
            {isCorporate() ? (
              <Link to={urls.general.corporate.register} className="b2b-ignore-desktop-link">
                <img alt="hotelian-logo" src={logo} style={{ height: 20 }} />
              </Link>
            ) : (
              <a href={gb2bWebsiteUrl} className="b2b-ignore-desktop-link">
                <img alt="hotelian-logo" src={logo} style={{ height: 20 }} />
              </a>
            )}
          </li>
        )}
        {generalDesktopNavMenu
          .filter(el => el.shouldRender)
          .map((el, i) => (
            <li key={i} className="text-white">
              {el.blank || el.normalLink ? (
                <a href={el.path} target={el.blank ? '_blank' : undefined} rel="noreferrer noopener">
                  {el.title}
                </a>
              ) : (
                <Link to={el.path}>{el.title}</Link>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default DesktopGeneralNav;
