import React from 'react';
import { Grid, Fade, CircularProgress } from '@material-ui/core';
import useLockedBody from '@hotelian/hooks/useLockedBody';

export const SSRSpinnerSeed: React.FC = () => {
  return <CircularProgress />;
};

interface ISSRSpinner {
  loading: boolean;
}

export const SSRSpinner: React.FC<ISSRSpinner> = ({ loading }) => {
  // useLockedBody(loading);
  const loadingCmp = (
    <Grid container className="ssr-spinner-ctn" alignItems="center" justifyContent="center">
      <Grid xs={12} item className="text-center">
        <SSRSpinnerSeed />
      </Grid>
    </Grid>
  );

  return (
    <Fade in={loading} style={{ transitionDelay: '200ms' }}>
      {loadingCmp}
    </Fade>
  );
};

export const LazySpinner: React.FC = () => {
  return <div className="loading" />;
};

export const SearchBoxItemSpinner: React.FC = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

interface IMainSpinner {
  className?: string;
}

export const MainSpinner: React.FC<IMainSpinner> = ({ className }) => {
  return (
    <div className={`my-5 py-5 d-flex justify-content-center align-items-center ${className ?? ''}`}>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
};

export const ImageSpinner: React.FC = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div>
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
