import { useSearchParams, useParams } from 'react-router-dom';
const useQuery = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  let query = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    query[param] = value;
  }
  query = { ...query, ...params };
  delete query['*'];
  return query;
};

export default useQuery;
