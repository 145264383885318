import { IActionStrategy, IStrategyEvent } from '../Strategy/broadcastStrategy.interfaces';

const eventName = 'window_message';

interface CustomWindowEvent<T = any> extends Event {
  readonly detail: T;
  initCustomEvent(typeArg: string, canBubbleArg: boolean, cancelableArg: boolean, detailArg: T): void;
}

type WindowEventDetail = { payload?: any; name: string };

export default class WindowObserver<EventNames> implements IActionStrategy<EventNames> {
  observers: IStrategyEvent<EventNames>[] = [];
  tabId: string | undefined;

  get canHandleCrossTab() {
    return false;
  }

  private static instance: WindowObserver<any>;

  private constructor() {}

  registerListener() {
    const onMessageFire = (event: CustomWindowEvent<WindowEventDetail>) => {
      if (event && event.detail) {
        const { payload, name } = event.detail;
        const chosenEvent = this.observers.find(item => item.name === name);
        if (chosenEvent) {
          if (typeof chosenEvent.callback === 'function') {
            chosenEvent.callback(payload);
          } else {
            chosenEvent.callback.windowCallback(payload);
          }
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener(eventName, onMessageFire as unknown as EventListener);
    }
  }

  static getInstance<T>(): WindowObserver<T> {
    if (!WindowObserver.instance) {
      WindowObserver.instance = new WindowObserver();
    }

    return WindowObserver.instance;
  }

  fire({ name, payload }: { name: EventNames; payload?: any }) {
    const Event = new CustomEvent(eventName, { detail: { payload, name } });
    window.dispatchEvent(Event);
  }
}
