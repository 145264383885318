import { BackendError } from '@hotelian/config/types';

class BackendErrorExtractor {
  code: number | undefined;
  message: string | undefined;
  status: number | undefined;
  name: string | undefined;
  type: string | undefined;

  static handle(values: BackendError) {
    const ErrorToReturn = new BackendErrorExtractor();
    ErrorToReturn.code = values?.response?.data?.result?.code;
    ErrorToReturn.message = values?.response?.data?.result?.message;
    ErrorToReturn.status = values?.response?.status;
    ErrorToReturn.name = values?.response?.data?.result?.name;
    ErrorToReturn.type = values?.response?.data?.result?.type;
    return ErrorToReturn;
  }
}

export default BackendErrorExtractor;
