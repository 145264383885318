import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { faThList, faUser } from '@fortawesome/pro-light-svg-icons';

import { generalMobileNavMenu } from 'constants/navMenu';
import { AuthContext } from 'contexts';
import logo from '@hotelian/assets/hotelian-logo-topbar.svg';
import urls from '../../constants/urls';

const MobileGeneralDrawer = ({ open, setOpen, withoutLogin }) => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const { pathname } = useLocation();
  const getBtn = (el, isActive) => (
    <Button
      onClick={() => {
        setOpen(false);
      }}
      size="large"
      className="w-100 d-block text-white px-3"
      color={isActive ? 'secondary' : undefined}
      variant={isActive ? 'contained' : undefined}
    >
      <div className="w-100 d-flex align-items-center">
        <div className="mr-3">{el.icon}</div>
        <div>{el.title}</div>
      </div>
    </Button>
  );
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
    >
      <div className="b2b-app-mobile-drawer">
        <div className="b2b-app-mobile-drawer-general-detail">
          <img src={logo} alt="hotelian-logo" className="w-50" />
        </div>
        <div className="b2b-app-mobile-drawer-menu-wrapper text-white">
          <nav>
            <ul className="list-unstyled p-0 m-0">
              {withoutLogin ? null : (
                <li>
                  <Link to={isLoggedIn ? urls.app.view : urls.user.login}>
                    {getBtn({
                      icon: isLoggedIn ? <FontAwesomeIcon icon={faThList} /> : <FontAwesomeIcon icon={faUser} />,
                      title: isLoggedIn ? <FormattedMessage id="all.dashboard" /> : <FormattedMessage id="all.login" />,
                    })}
                  </Link>
                </li>
              )}
              {generalMobileNavMenu
                .filter(el => el.shouldRender)
                .map((el, i) => {
                  const isActive = el.path === pathname;
                  return (
                    <li key={i}>
                      {el.blank || el.normalLink ? (
                        <a href={el.path} target={el.blank ? '_blank' : undefined} rel="noreferrer noopener">
                          {getBtn(el, isActive)}
                        </a>
                      ) : (
                        <Link to={el.path}>{getBtn(el, isActive)}</Link>
                      )}
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileGeneralDrawer;
