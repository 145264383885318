import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, CircularProgress, Grid, Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { Gateways, CommaSeparatedInput } from '@hotelian/components/Inputs';
import { AuthContext, UIContext } from 'contexts';
import api from '@hotelian/constants/api';
import { IncreaseBalanceSchema } from 'helpers/validations';
import PriceComponent from '@hotelian/components/PriceComponent';
import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import ExceptionOf422 from '../utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';
import { notAllowedToBookCodes } from '@hotelian/constants/backend-codes/reserve';
import { useLoadingContext } from '../utils/hooks/contextHooks';
import { increaseBalanceGatewayAdapter, stripeAdapter } from '@hotelian/helpers/gatewayAdapter';
import { calcSum, rounderNumberUp } from '@hotelian/utils';
import { getStripeImageURL } from '@hotelian/constants/defaultValues';

const IncreaseBalanceDialog = ({ open, toggle }) => {
  const { formatMessage } = useIntl();
  const { getUserDetail } = useContext(AuthContext);
  const { loading, setLoading } = useLoadingContext();
  const { handleOpen403Dialog } = useContext(UIContext);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (open) {
      getUserDetail().then(setUserData);
    } else {
      window.setTimeout(() => {
        setUserData(null);
      }, 500);
    }
  }, [open]);

  const handleSubmit = async (values, { setFieldError }) => {
    const { amount, gateway } = values;
    const body = { amount, gateway };

    try {
      setLoading(true);

      const { redirect_id } = await ResponseHandler.freeHandling(
        Api.call(
          { url: api.b2b.user.transaction, data: body, method: 'post' },
          {
            errorOptions: {
              422: error => {
                ExceptionOf422(error, { setFieldError });
              },
              403: (error, { defaultHandler }) => {
                const { code, name, message } = BackendErrorExtractor.handle(error);
                if (notAllowedToBookCodes.includes(code)) {
                  handleOpen403Dialog(true, {
                    title: name,
                    message,
                  });
                } else {
                  defaultHandler();
                }
              },
            },
          }
        )
      );

      const { url } = await ResponseHandler.freeHandling(
        Api.call({ url: api.b2b.user.redirectTransaction, params: { redirect_id } })
      );

      window.location.assign(url);
    } catch (_err) {
    } finally {
      setLoading(false);
    }
  };

  const gateways = userData?.available_gateways;

  const calcFinalPrice = (gateway, price) => {
    const chosenGateway = gateways.find(el => el.name === gateway);
    const feePercentage = chosenGateway?.fee_percentage;
    const gatewayCost = rounderNumberUp((feePercentage / 100) * Number(price), 2);
    const finalPrice = rounderNumberUp(calcSum([Number(price), gatewayCost]), 2);

    return { feePercentage, finalPrice, gatewayCost };
  };

  return (
    <Dialog open={open} onClose={toggle} maxWidth="xs" fullWidth>
      <DialogContent>
        {userData ? (
          <div>
            <Formik
              initialValues={{
                amount: '',
                gateway: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={IncreaseBalanceSchema}
            >
              {({ values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="mb-1">
                        <span className="text-muted mr-2">
                          <FormattedMessage id="all.current-balance" />:
                        </span>
                        <PriceComponent
                          price={userData?.agency?.cash_credit}
                          currency={userData?.agency?.currency}
                          currencyClasses="text-muted"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={CommaSeparatedInput}
                        name="amount"
                        label={<FormattedMessage id="transaction.amount" />}
                        placeholder={formatMessage({
                          id: 'transaction.increase-balance-amount',
                        })}
                        endAdornment={<span className="color-theme">{userData?.agency?.currency?.content}</span>}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Box pb={1}>
                          <div className="color-theme font-weight-bold">
                            <FormattedMessage id="all.gateway" />
                          </div>
                        </Box>
                        <Field
                          component={Gateways}
                          autoSelect
                          size="small"
                          name="gateway"
                          options={increaseBalanceGatewayAdapter(
                            stripeAdapter(gateways, {
                              stripeImageUrl: getStripeImageURL(process.env.REACT_APP_BACKEND_URL),
                            })
                          )?.map(el => ({
                            label: (
                              <div className="gateways-image-wrapper">
                                <img src={el.image} alt={el.name} className="h-100 w-100 object-fit-contain" />
                              </div>
                            ),
                            value: el.name,
                          }))}
                        />
                      </Box>
                    </Grid>

                    {values.gateway && values.amount ? (
                      <Grid container item xs={12} spcaing={2} className="font-weight-bold">
                        <Grid item xs={12}>
                          <FormattedMessage id="increase-balance.gateway-cost" />:
                          {calcFinalPrice(values.gateway, values.amount)?.feePercentage ? (
                            <Fragment>
                              <span className="ml-1">
                                +
                                <PriceComponent
                                  price={calcFinalPrice(values.gateway, values.amount)?.gatewayCost}
                                  currency={userData?.agency?.currency}
                                  containerClasses="mr-1 d-inline-block"
                                />
                              </span>
                              <span className="font-weight-bold">
                                ({calcFinalPrice(values.gateway, values.amount)?.feePercentage}%)
                              </span>
                            </Fragment>
                          ) : (
                            <span className="text-success ml-1">
                              <FormattedMessage id="increase-balance.no-gateway-fee" />
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormattedMessage id="increase-balance.final-price" />:
                          <PriceComponent
                            price={calcFinalPrice(values.gateway, values.amount)?.finalPrice}
                            currency={userData?.agency?.currency}
                            containerClasses="ml-1 d-inline-block"
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        size="large"
                        className="my-2"
                        disabled={loading}
                      >
                        <FormattedMessage id="all.submit" />
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <Box py={8} className="d-flex justify-content-center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IncreaseBalanceDialog;
