import { AxiosError } from 'axios';
import { FormikHelpers, FormikProps } from 'formik';

const responseValidationErrorHandler = (
  err: AxiosError,
  setFieldError?: FormikHelpers<any>['setFieldError'] | FormikProps<any>['setFieldError'],
  showSnackbar?: boolean
) => {
  if (err?.response?.status === 422) {
    if (showSnackbar) {
      const { result } = err.response.data;
      const messageObj = result?.[0] ? result[0] : result;
      window.openSnackbar(messageObj?.message, 'error');
    } else if (setFieldError) {
      err.response.data.result.forEach((el: any) => {
        setFieldError(el.field, el.message);
      });
    }
  }
  return null;
};

export default responseValidationErrorHandler;
