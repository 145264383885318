import React, { Suspense } from 'react';
import ContextsProvider from 'contexts/Provider';
import hotelianLogger from '@hotelian/utils/hotelianLogger';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

// import css
import '@hotelian/assets/globals.css';
import './b2b.css';
import './mobile-b2b.css';
import '@hotelian/assets/swiper.css';
import 'cropperjs/dist/cropper.css';
/* font awesome pro configuration
https://fontawesome.com/how-to-use/on-the-web/setup/using-package-managers#installing-pro */
import { getToken } from 'utils';
import MainErrorHandler from 'components/MainErrorHandler';
import userRoutes from 'config/routes/userRoutes';
import appRoutes from 'config/routes/appRoutes';
import generalRoutes from 'config/routes/generalRoutes';
import RouteRenderer from 'components/RouteRenderer';
import fallbackRoutes from 'config/routes/fallbackRoutes';
import { redirectRoutes } from 'config/routes';
import urls from 'constants/urls';
import { isCorporate } from './helpers';

/*
   -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
*/

hotelianLogger('secondary'); /** application main log (HOTELIAN.COM) */

/*
   -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
*/

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const ProtectedRoute = () => {
  if (Boolean(getToken())) {
    return <Navigate replace to={urls.app.view} />;
  }
  return <Navigate replace to={urls.user.login} />;
};

const App = () => {
  return (
    <Suspense fallback={<div className="loading" />}>
      <MainErrorHandler>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ContextsProvider>
              <Routes>
                {redirectRoutes.map(({ to, from, id }) => (
                  <Route key={id} element={<Navigate to={to} />} path={from} />
                ))}
                <Route path="/" element={<ProtectedRoute />} />
                <Route path={appRoutes.mainPrefix} exact element={<RouteRenderer routeObject={appRoutes} />} />
                <Route path={userRoutes.mainPrefix} exact element={<RouteRenderer routeObject={userRoutes} />} />
                {isCorporate() && (
                  <Route
                    path={generalRoutes.mainPrefix}
                    exact
                    element={<RouteRenderer routeObject={generalRoutes} />}
                  />
                )}
                <Route path={fallbackRoutes.mainPrefix} element={<RouteRenderer routeObject={fallbackRoutes} />} />
                <Route path="*" element={<Navigate replace to={urls.fallback.error404} />} />
              </Routes>
            </ContextsProvider>
          </Router>
        </QueryClientProvider>
      </MainErrorHandler>
    </Suspense>
  );
};

export default App;
