import { AxiosError } from 'axios';

import { Instance } from 'utils/AxiosInstance';
import { IDefaultException } from '@hotelian/utils/ErrorHandler';
import BroadcastStrategy from '@hotelian/utils/Strategy/BroadcastStrategy';
import { IUIActionNames } from '../../../interfaces';

const observer = BroadcastStrategy.getInstance<IUIActionNames>();

const ExceptionOf401: IDefaultException = async (error: AxiosError, { requestHelpers }) => {
  return new Promise(async resolve => {
    if (error?.response?.status === 401 && typeof window !== 'undefined') {
      observer.fire({ name: 'TOKEN_EXPIRED' });
      const res: { token: string; resolve: (value: unknown) => void } = await new Promise((resolve, reject) => {
        Instance.failedRequestQueue.push({ resolve, reject });
      });
      if (res && error.config) {
        requestHelpers.retry(() => {
          resolve();
          res.resolve('');
        });
      }
    }
  });
};

export default ExceptionOf401;
