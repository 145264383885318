import { lazyWithRetry } from 'utils';
import historyRoutes from './historyRoutes';
import profileRoutes from './profileRoutes';
import supportRoutes from './supportRoutes';
import agencyRoutes from './agencyRoutes';
import invoiceRoutes from './invoiceRoutes';
import transactionRoutes from './transactionRoutes';
import servicesRoutes from './servicesRoutes';
import hotelRoutes from './hotelRoutes';
import paymentRoutes from './paymentRoutes';
import AppLayout from 'layouts/AppLayout';
import urls from 'constants/urls';
import fallbackRoutes from '../fallbackRoutes';
import { isCorporate } from 'helpers';

const ViewMain = lazyWithRetry(() => import(/* webpackChunkName: "views-search" */ 'views/app/main'));

const agencyRoutesObject = isCorporate() ? [] : agencyRoutes;

const appRoutes = {
  mainPrefix: urls.app.mainPrefix,
  Layout: AppLayout,
  routes: [
    { id: 'main', Component: ViewMain, path: '/' },
    ...historyRoutes,
    ...profileRoutes,
    ...supportRoutes,
    ...agencyRoutesObject,
    ...invoiceRoutes,
    ...transactionRoutes,
    ...servicesRoutes,
    ...hotelRoutes,
    ...paymentRoutes,
    ...fallbackRoutes.routes,
  ],
};

export default appRoutes;
