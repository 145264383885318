import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isString } from '@hotelian/utils';

const useErrorBoundary = ({ resetErrorBoundary, error }) => {
  const { pathname } = useLocation();

  const [mounted, setMounted] = useState(false);
  const [errorFoundedAt, setErrorFoundedAt] = useState(pathname);

  const tryAgainAttemptCountKey = 'error-retry-count';
  const hasPageRefreshedKey = 'page-has-been-force-refreshed';
  const hasPageRefreshed = JSON.parse(window.sessionStorage.getItem(hasPageRefreshedKey) || 'false');
  const tryAgainAttemptCount = JSON.parse(sessionStorage.getItem(tryAgainAttemptCountKey) || '0');

  const resetTryCount = () => {
    sessionStorage.setItem(tryAgainAttemptCountKey, JSON.stringify(0));
  };

  const tryAgain = () => {
    if (tryAgainAttemptCount === 3) {
      resetTryCount();
      window.location.reload();
    } else {
      sessionStorage.setItem(tryAgainAttemptCountKey, JSON.stringify(Number(tryAgainAttemptCount) + 1));
      resetErrorBoundary();
    }
  };

  useEffect(() => {
    const errorName = error?.error?.name;
    if (isString(errorName) && errorName === 'ChunkLoadError') {
      // ChunkLoadError error example
      /*
      {
    "error": {
        "message": "Loading chunk 2859 failed.\n(missing: http://46.105.252.234/static/js/views-history-detail.332caff3.chunk.js)",
        "name": "ChunkLoadError",
        "type": "missing",
        "request": "http://46.105.252.234/static/js/views-history-detail.332caff3.chunk.js"
    },
    "errorInfo": {
        "componentStack": "\n    at Lazy\n    at h (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:957793)\n    at Suspense\n    at n.default (http://46.105.252.234/static/js/views-history.07915014.chunk.js:1:437)\n    at h (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:957793)\n    at Suspense\n    at div\n    at main\n    at div\n    at S (http://46.105.252.234/static/js/views-app.66f715ec.chunk.js:1:7053)\n    at U (http://46.105.252.234/static/js/views-app.66f715ec.chunk.js:1:12856)\n    at ae (http://46.105.252.234/static/js/views-app.66f715ec.chunk.js:1:14852)\n    at h (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:957793)\n    at f (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:942439)\n    at un (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1364532)\n    at dn (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1366862)\n    at on (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1356732)\n    at Zt (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1352445)\n    at St (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1350366)\n    at t (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:908806)\n    at A (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1316302)\n    at m (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:520450)\n    at mt (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1344954)\n    at wt (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1345273)\n    at Ot (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1350104)\n    at pn (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1366988)\n    at p (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:957052)\n    at d (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:953944)\n    at Suspense\n    at c (http://46.105.252.234/static/js/main.eb6eb0f6.js:2:1370540)"
    }
}
      */

      sessionStorage.setItem(tryAgainAttemptCountKey, JSON.stringify(0));
      window.sessionStorage.setItem(hasPageRefreshedKey, 'true');
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    if (pathname !== errorFoundedAt) {
      resetTryCount();
      setErrorFoundedAt(pathname);
      resetErrorBoundary();
    }
  }, [pathname]);

  if (!mounted) {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.sessionStorage.getItem(hasPageRefreshedKey) || 'false');
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.sessionStorage.setItem(hasPageRefreshedKey, 'true');
      setTimeout(() => tryAgain(), 1000);
    } else {
      window.sessionStorage.setItem(hasPageRefreshedKey, 'false');
    }
  }

  useEffect(() => {
    setMounted(true);
    localStorage.setItem('b2b_last_error', JSON.stringify(error));
    sessionStorage.setItem('b2b_last_error', JSON.stringify(error));
    /*independentAxios.post(api.b2b.general.errorReport, {
      error: {
        error,
      },
    });*/
  }, []);
  return { hasPageRefreshed, tryAgain };
};

export default useErrorBoundary;
