import useErrorBoundary from 'utils/hooks/useErrorBoundary';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { isInProduction } from 'utils';

const B2BAppDesktopErrorBoundary = ({ error, resetErrorBoundary }) => {
  const { hasPageRefreshed, tryAgain } = useErrorBoundary({ resetErrorBoundary, error });
  return (
    <div className="absolute-center">
      {!hasPageRefreshed ? (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <img src="/images/bugFound.svg" style={{ width: '35%', height: '35%' }} alt="error found" />
          <Typography color="secondary.main" style={{ padding: 30 }} variant="h6" component="h6">
            {isInProduction ? <FormattedMessage id="frontend-500-error.production.title" /> : error.message}
          </Typography>
          <Button color="primary" size="lg" onClick={tryAgain} variant="contained">
            <FormattedMessage id="frontend-500-error.try-again-btn" />
          </Button>
        </div>
      ) : (
        <div className="loading" />
      )}
    </div>
  );
};
export default B2BAppDesktopErrorBoundary;
