import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  Grid,
  FormControl,
  Button,
  MenuItem,
  Select,
  FormHelperText,
  Popover,
  InputBase,
  TextareaAutosize,
  Checkbox as MuiCheckbox,
  Radio as MuiRadio,
  FormControlLabel,
  Divider,
  IconButton,
  Slide,
  RadioGroup,
  FormLabel,
  OutlinedInput,
  CircularProgress,
  Box,
  Badge,
  TextField,
  Typography,
  Fade,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Search as SearchIcon, Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';
import { faTimes, faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import NumberFormat from 'react-number-format';

import prefixesData from '@hotelian/constants/prefixesData';
import useMUIStyles from '@hotelian/assets/muiStyles';
import { SearchBoxItemSpinner } from './Spinners';
import { LanguageContext } from 'contexts';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone';
import { selectDefaultItem } from '@hotelian/constants/types-config/defaults';

export const TextInput = ({ field, form, label, className, errorText, errorOnTop, ...props }) => {
  return (
    <div>
      {label ? <label className="font-weight-bold color-theme d-block mb-2">{label}</label> : null}
      <div className="position-relative">
        <OutlinedInput
          className={`w-100 custom-outlined-input ${className ?? ''}`}
          error={form?.errors?.[field.name] && form?.touched?.[field.name]}
          autoComplete="off"
          {...field}
          {...props}
        />
        {errorText ? (
          <div className={`${errorOnTop ? 'validation-error-on-top' : 'validation-error-normal'} mb-1`}>
            {errorText}
          </div>
        ) : form?.errors?.[field.name] && form?.touched?.[field.name] ? (
          <div className={`${errorOnTop ? 'validation-error-on-top' : 'validation-error-normal'} mb-1`}>
            {form.errors[field.name]}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  );
};

export const CommaSeparatedInput = ({ field, form, label, className, errorText, errorOnTop, ...props }) => {
  return (
    <div>
      {label ? <label className="font-weight-bold color-theme d-block mb-2">{label}</label> : null}
      <div>
        <TextField
          className={`w-100 custom-outlined-input ${className ?? ''}`}
          error={form?.errors?.[field.name] && form?.touched?.[field.name]}
          autoComplete="off"
          {...field}
          {...props}
          variant="outlined"
          value={form.values[field.name]}
          InputProps={{
            inputComponent: NumberFormatCustom,
            endAdornment: props?.endAdornment ?? undefined,
          }}
          onChange={e => {
            form.setFieldValue(field.name, e.target.value);
            if (typeof props.onChange === 'function') {
              props.onChange(e);
            } else if (props.onChange) {
              console.error('onChange is not a function (Comma Separated Input)');
            }
          }}
        />
        {errorText ? (
          <div className={`${errorOnTop ? 'validation-error-on-top' : 'validation-error-normal'} mb-1`}>
            {errorText}
          </div>
        ) : form?.errors?.[field.name] && form?.touched?.[field.name] ? (
          <div className={`${errorOnTop ? 'validation-error-on-top' : 'validation-error-normal'} mb-1`}>
            {form.errors[field.name]}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const TextArea = ({ field, form, className, label, ...props }) => {
  return (
    <Fragment>
      <div className="position-relative">
        {label ? <label className="font-weight-bold color-theme d-block mb-2">{label}</label> : null}
        <TextareaAutosize minRows={14} className={`with-radius ${className ?? ''}`} {...props} {...field} />
        {form.errors[field.name] && form.touched[field.name] ? (
          <div className="validation-error">{form.errors[field.name]}</div>
        ) : null}
      </div>
    </Fragment>
  );
};
export const Checkbox = ({ field, form, label, ...props }) => {
  return (
    <Fragment>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={form.values[field.name]}
            onChange={e => form.setFieldValue(field.name, e.target.checked)}
            color="primary"
            {...props}
            {...field}
          />
        }
        label={label}
      />
    </Fragment>
  );
};
const IntellisenseItem = ({ el, getOptions, onSelect, index, currentFocusIndex, allData, className }) => {
  return (
    <li className="p-0 border-bottom">
      <Button
        style={{
          backgroundColor: currentFocusIndex === index ? '#f5f5f5' : undefined,
        }}
        onClick={e => {
          e.stopPropagation();
          onSelect(el);
        }}
        className={`w-100 h-100 py-2 radius-0 ${index + 1 !== allData.length ? '' : ''} ${className ?? ''}`}
      >
        {getOptions(el, index)}
      </Button>
    </li>
  );
};
export const Intellisense = ({
  onInputChange,
  dataList,
  draftData,
  onSelect,
  loading,
  getOptions,
  ctnStyle,
  ctnClassName,
  openIntellisenseCtn,
  setOpenIntellisenseCtn,
  afterOpenIntellisense,
  getSkeleton,
  poweredByGoogle,
  spinner,
  intellisenseCtnClassName,
  withClearButton = false,
  onClickClear = () => {},
  noOptionsText,
  ...props
}) => {
  const [hasInputChanged, setHasInputChanged] = useState(false);

  /* props => dataList, onSelect, getOptions */
  const validDataList = () => {
    if (props.value?.trim()?.length < 2) {
      if (draftData?.length) {
        return draftData;
      } else {
        return;
      }
    } else if ((hasInputChanged && !dataList) || loading) {
      return 'loading';
    } else if (!dataList?.length) {
      if (!dataList) {
        if (props.value?.trim()?.length > 2) {
          if (draftData?.length) {
            return draftData;
          } else if (!hasInputChanged) {
            return draftData;
          } else {
            return 'loading';
          }
        } else {
          return 'loading';
        }
      } else {
        return [];
      }
    } else {
      return dataList;
    }
  };
  const [currentFocusIndex, setCurrentFocusIndex] = useState(null);

  const openIntellisense = () => {
    setTimeout(() => {
      afterOpenIntellisense();
      setOpenIntellisenseCtn(true);
    }, 80);
  };

  const closeIntellisense = () => {
    setOpenIntellisenseCtn(false);
  };

  const onKeyChange = e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      if (typeof currentFocusIndex === 'number' && validDataList()?.length) {
        onSelect(validDataList()[currentFocusIndex]);
        closeIntellisense();
      }
      return;
    }
    if (openIntellisenseCtn && validDataList()?.length) {
      if (currentFocusIndex === null) {
        setCurrentFocusIndex(0);
      } else {
        if (e.code === 'ArrowDown') {
          setCurrentFocusIndex(lastVal => lastVal + 1);
        } else if (e.code === 'ArrowUp') {
          setCurrentFocusIndex(lastVal => lastVal - 1);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', closeIntellisense);
  }, []);

  useEffect(() => {
    if (currentFocusIndex < 0) {
      setCurrentFocusIndex(validDataList()?.length - 1);
    } else if (currentFocusIndex > validDataList()?.length - 1) {
      setCurrentFocusIndex(0);
    }
  }, [currentFocusIndex]);

  useEffect(() => {
    if (typeof currentFocusIndex === 'number' && !openIntellisenseCtn) {
      setCurrentFocusIndex(null);
    }
  }, [openIntellisenseCtn]);

  return (
    <div className={`position-relative ${ctnClassName ? ctnClassName : ''}`} style={ctnStyle}>
      {spinner ? (
        <div className="search-box-item-spinner-wrapper">
          <SearchBoxItemSpinner />
        </div>
      ) : null}
      <OutlinedInput
        onChange={e => {
          !hasInputChanged && setHasInputChanged(true);
          if (typeof onInputChange === 'function') {
            onInputChange(e);
          }
        }}
        className="intellisense-input w-100"
        // variant="outlined"
        inputProps={{
          tabIndex: 1,
          onBlur: () => {
            setCurrentFocusIndex(null);
          },
          className: 'text-truncate',
          'aria-autocomplete': 'none',
        }}
        onKeyDown={onKeyChange}
        autoComplete="off"
        onFocus={_ => {
          openIntellisense();
        }}
        onMouseDown={e => {
          if (openIntellisenseCtn) {
            e.stopPropagation();
          }
        }}
        endAdornment={
          <Fade in={Boolean(withClearButton && props.value)}>
            <IconButton className={`text-muted`} size="small" onClick={onClickClear}>
              <FontAwesomeIcon icon={faXmarkCircle} />
            </IconButton>
          </Fade>
        }
        {...props}
      />
      {openIntellisenseCtn && Boolean(validDataList()) ? (
        <div
          className={`intellisense-items-ctn with-radius ${intellisenseCtnClassName ?? ''}`}
          onMouseDown={e => {
            if (openIntellisenseCtn) {
              e.stopPropagation();
            }
          }}
        >
          <div style={{ zIndex: 3 }}>
            {validDataList() === 'loading' ? (
              <div>
                {[1, 2, 3, 4, 5].map((_, i) => (
                  <div key={i} className="p-2 border-bottom">
                    {getSkeleton()}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {validDataList().length ? (
                  <ul className="p-0 m-0 list-unstyled">
                    {validDataList().map((el, i) => (
                      <IntellisenseItem
                        onSelect={val => {
                          onSelect(val);
                          setHasInputChanged(false);
                          closeIntellisense();
                        }}
                        allData={validDataList()}
                        getOptions={getOptions}
                        key={i}
                        index={i}
                        el={el}
                        currentFocusIndex={currentFocusIndex}
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="p-3 text-muted">{noOptionsText}</div>
                )}
              </div>
            )}
            {poweredByGoogle && (
              <div className="p-2 make-ltr">
                <img
                  style={{ height: 15 }}
                  className="w-auto"
                  src="/images/powere-by-google.jpg"
                  alt="powere-by-google"
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const MobileIntellisense = ({
  onInputChange,
  dataList,
  onSelect,
  afterSelect,
  loading,
  getOptions,
  label,
  inputPlaceholder,
  modalTitle,
  openIntellisenseCtn,
  openIntellisense,
  closeIntellisense,
  filledDataContent,
  draftData,
  startAdornment,
  searchedValue,
  placeholder,
  inputStartAdornment,
  changeScrollVis,
  poweredByGoogle,
  spinner,
  withClearButton = false,
  onClickClear = () => {},
  noOptionsText,
  ...props
}) => {
  const classes = useMUIStyles();
  const validDataList = () => {
    if (loading) {
      return;
    } else if (!dataList?.length && !props.value?.trim()?.length) {
      if (draftData?.length) {
        return draftData;
      } else {
        return;
      }
    } else {
      return dataList;
    }
  };
  useEffect(() => {
    if (typeof changeScrollVis === 'function') {
      changeScrollVis(openIntellisenseCtn);
    }
  }, [openIntellisenseCtn]);
  return (
    <Fragment>
      <Grid item xs={12}>
        <div className="position-relative">
          <label className="color-theme font-weight-bold mb-1 d-block">{label}</label>
          <div onClick={openIntellisense} className="position-relative disable-input-focus-ctn" style={{ zIndex: 10 }}>
            {spinner ? (
              <div className="search-box-item-spinner-wrapper">
                <SearchBoxItemSpinner />
              </div>
            ) : null}
            <div className="disable-input-focus" />
            <OutlinedInput
              className="w-100 intellisense-input"
              value={searchedValue}
              onChange={() => {
                return;
              }}
              startAdornment={startAdornment}
              placeholder={placeholder}
              inputProps={{
                className: 'text-truncate',
              }}
            />
          </div>
          <Slide mountOnEnter unmountOnExit direction="up" in={openIntellisenseCtn}>
            <div className="mobile-intellisense-items-ctn">
              <div className="content__wrapper">
                <Box py={1}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3 className="color-theme h5 m-0 p-0">{modalTitle}</h3>
                    </div>
                    <div>
                      <IconButton onClick={closeIntellisense} className="hover-transparent">
                        <FontAwesomeIcon icon={faTimes} />
                      </IconButton>
                    </div>
                  </div>
                </Box>
                <Box py={1}>
                  <div className="position-relative">
                    <OutlinedInput
                      startAdornment={inputStartAdornment}
                      autoFocus
                      placeholder={inputPlaceholder}
                      onChange={onInputChange}
                      className="w-100 intellisense-input"
                      inputProps={{
                        className: 'text-truncate',
                      }}
                      endAdornment={
                        <Fade in={Boolean(withClearButton && props.value)}>
                          <IconButton className={`text-muted`} size="small" onClick={onClickClear}>
                            <FontAwesomeIcon icon={faXmarkCircle} />
                          </IconButton>
                        </Fade>
                      }
                      {...props}
                    />
                    {loading ? (
                      <div className={classes.inputsCircularLoading}>
                        <CircularProgress color="primary" size={17} />
                      </div>
                    ) : null}
                  </div>
                </Box>
                <div>
                  {validDataList()?.length ? (
                    <ul className="p-0 m-0 list-unstyled">
                      {validDataList().map((el, i) => (
                        <IntellisenseItem
                          onSelect={val => {
                            onSelect(val);
                            afterSelect();
                          }}
                          allData={validDataList()}
                          getOptions={getOptions}
                          key={i}
                          index={i}
                          el={el}
                          className="justify-content-start"
                        />
                      ))}
                    </ul>
                  ) : validDataList() && !validDataList()?.length ? (
                    <div className="p-3 text-center text-muted">{noOptionsText}</div>
                  ) : null}
                  {poweredByGoogle ? (
                    <div className="p-2 make-ltr">
                      <img
                        style={{ height: 15 }}
                        className="w-auto"
                        src="/images/powere-by-google.jpg"
                        alt="powere-by-google"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </Grid>
    </Fragment>
  );
};
export const Selection = ({ field, form, className, label, transparent, options, errorOnTop, ...props }) => {
  // Pass options in props for map <MenuItem/>  -->  ex) options={ value: 'example value', label: 'example label'}
  return (
    <FormControl variant="outlined">
      {Boolean(label) ? <label className="color-theme font-weight-bold mb-2 d-block">{label}</label> : null}
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        className={`${transparent ? '' : 'bg-foreground-gray'} ${className ?? ''}`}
        error={form?.errors?.[field.name] && form?.touched?.[field.name]}
        onChange={e => {
          form?.setFieldValue(field.name, e.target.value);
        }}
        {...field}
        {...props}
        autoComplete="off"
      >
        {options?.map((el, i) => (
          <MenuItem key={i} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
      {form?.errors?.[field.name] && form?.touched?.[field.name] ? (
        <div className={`${errorOnTop ? 'validation-error-on-top' : 'validation-error-normal'} mb-1`}>
          {form?.errors?.[field.name]}
        </div>
      ) : null}
    </FormControl>
  );
};
export const CodeValidator = ({ field, form, label, timerCmp, ...props }) => (
  <div className="w-100">
    {label ? <label className="font-weight-bold color-theme d-block mb-2">{label}</label> : null}
    <div>
      <TextInput
        field={field}
        form={form}
        {...props}
        endAdornment={timerCmp ? <div className="code-validator-timer-cmp">{timerCmp}</div> : null}
      />
    </div>
  </div>
);

const useStyles = makeStyles(theme => ({
  root: {
    width: 221,
    fontSize: 13,
  },
  poperCtn: {
    border: '1px solid rgba(27,31,35,.15)',
    width: 300,
    zIndex: 1500,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#ffffff',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  searchRoot: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  searchDivider: {
    height: 28,
    margin: 4,
  },
}));

export const PhoneNumber = ({
  field,
  form,
  inputProps,
  withoutLabel,
  mobileMode,
  changeScrollVis,
  userCountryCode,
  changeUserCountryCode,
  mobileTopZero,
  simpleImage,
  prefixName,
  prefixValue,
  options = prefixesData,
  ...props
}) => {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const { direction } = useContext(LanguageContext);

  // prefixName - prefixValue - name - value
  const [anchorEl, setAnchorEl] = useState(null);

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value) {
      form.setFieldValue(prefixName, value?.dialCode);
    }
  }, [value]);

  useEffect(() => {
    if (userCountryCode && !Boolean(prefixValue)) {
      const valueToSet = options.find(el => el.isoCode === userCountryCode);
      if (valueToSet) {
        setValue(valueToSet);
      }
    }
  }, [userCountryCode]);

  useEffect(() => {
    if (Boolean(prefixValue)) {
      setValue(options.find(el => el.dialCode === prefixValue));
    } else {
      const valueToSet = options.find(el => el.isoCode === userCountryCode) ?? options.find(el => el.default);

      setValue(valueToSet);
      form.setFieldValue(prefixName, valueToSet?.dialCode);
    }
  }, [prefixValue]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event, reason) => {
    if (reason === 'toggleInput' || mobileMode) {
      return;
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;
  useEffect(() => {
    if (mobileMode) {
      if (Boolean(changeScrollVis)) {
        changeScrollVis(open);
      }
      const numberPicker = document.querySelector('.mobile-phone-number-picker');
      if (
        Boolean(open) &&
        document.querySelector('body').classList.contains('rtl') &&
        !numberPicker?.classList?.contains('rtl')
      ) {
        numberPicker?.classList?.add('rtl');
      }
    }
  }, [open, mobileMode]);

  const inputPlaceholder = formatMessage({ id: 'register.phone-number.title' });
  const ctn = (
    <div
      className={`${
        mobileMode ? 'content__wrapper h-100 d-flex flex-column mobile-phone-number-picker' : classes.poperCtn
      }`}
    >
      <div className={classes.header}>
        <div className={`${mobileMode ? 'd-flex align-items-center justify-content-between flex-nowrap' : ''}`}>
          <div>
            <FormattedMessage id="phone-number.description" />
          </div>
          {mobileMode ? (
            <div>
              <IconButton
                size="small"
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
        </div>
      </div>
      <Autocomplete
        noOptionsText={<FormattedMessage id="combo.no-options" />}
        open
        onClose={handleClose}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal,
        }}
        onChange={(_event, newValue) => {
          setValue(newValue);
          if (Boolean(changeUserCountryCode)) {
            changeUserCountryCode(newValue.isoCode);
          }
          if (mobileMode) {
            setAnchorEl(null);
          } else {
            handleClose();
          }
        }}
        disableCloseOnSelect
        disablePortal
        renderTags={() => null}
        renderOption={option => (
          <Fragment>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Box className="phone-number-countries-images-ctn" mr={1}>
                  <img loading="lazy" src={option.flag} alt={option.name} />
                </Box>
              </Grid>
              <Grid item>
                <div>
                  {option.name} {option.dialCode}
                </div>
              </Grid>
            </Grid>
          </Fragment>
        )}
        options={options}
        getOptionLabel={option => option.name + ' ' + option.dialCode}
        renderInput={params => (
          <InputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={formatMessage({
              id: 'phone-number.placeholder',
            })}
            className={classes.inputBase}
          />
        )}
      />
    </div>
  );

  return (
    <div>
      {withoutLabel ? null : <label className="font-weight-bold color-theme d-block mb-2">{inputPlaceholder}</label>}
      <div className="make-ltr">
        <Grid container direction="row" alignItems="stretch" spacing={1} className="phone-number-input">
          <Grid item className="phone-number-prefix-ctn h-100">
            <Button
              style={{ height: 55 }}
              variant="outlined"
              className="w-100"
              aria-describedby={id}
              size="large"
              onClick={handleClick}
            >
              <img
                key={value?.isoCode}
                src={value?.flag}
                alt={value?.name}
                style={{ width: 25, height: 'auto' }}
                className={`mx-2`}
              />
              <span>{value?.dialCode}</span>
            </Button>
            {mobileMode ? (
              <Slide mountOnEnter unmountOnExit direction="up" in={open}>
                <div className="mobile-intellisense-items-ctn" style={{ top: mobileTopZero ? 0 : undefined }}>
                  {ctn}
                </div>
              </Slide>
            ) : (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {ctn}
              </Popover>
            )}
          </Grid>
          <Grid item className="phone-number-number-input-ctn position-relative">
            <OutlinedInput
              error={form.errors[field.name] && form.touched[field.name]}
              {...field}
              {...inputProps}
              type="number"
              id="input-with-icon-grid"
              className={`custom-outlined-input w-100 ${direction === 'rtl' && !mobileMode ? 'px-3' : ''} ${
                inputProps?.className ?? ''
              }`}
              placeholder={inputPlaceholder}
            />
            {form.errors[field.name] && form.touched[field.name] ? (
              <div className="validation-error-normal">{form.errors[field.name]}</div>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export const B2CPhoneNumber = props => {
  return <PhoneNumber {...props} options={prefixesData?.filter(el => el.name.toLowerCase() !== 'iran')} />;
};

export const Search = ({ btnProps, ctnProps, onAcceptSearch, withoutDivider, transparent, white, ...props }) => {
  const classes = useStyles();
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onAcceptSearch();
      }}
      {...ctnProps}
      className={`border with-radius custom-search-component-form ${
        !transparent && !white ? 'bg-foreground-gray' : ''
      } ${white ? 'bg-white' : ''} ${classes.searchRoot} ${ctnProps?.className ?? ''}`}
    >
      <InputBase className={classes.searchInput} {...props} />
      {withoutDivider ? null : <Divider className={classes.searchDivider} orientation="vertical" />}
      <IconButton
        onClick={onAcceptSearch}
        color="primary"
        className={`${classes.searchIconButton} ${btnProps?.className ?? ''}`}
        {...btnProps}
      >
        <SearchIcon />
      </IconButton>
    </form>
  );
};

export const Radio = ({ field, form, enableRemove, label, color, options, ...props }) => {
  return (
    <Fragment>
      <FormControl component="fieldset" error={form.errors[field.name] && form.touched[field.name]}>
        {label ? <FormLabel component="legend">{label}</FormLabel> : null}
        <RadioGroup
          {...props}
          {...field}
          value={form.values[field.name]}
          onChange={e => {
            form.setFieldValue(field.name, e.target.value);
          }}
        >
          {options.map((el, i) => {
            const checked = form.values[field.name] === el.value;
            return (
              <FormControlLabel
                key={i}
                value={el.value}
                checked={checked}
                control={
                  <MuiRadio
                    onClick={e => {
                      if (enableRemove && checked) {
                        form.setFieldValue(field.name, '');
                      }
                    }}
                    color={color}
                  />
                }
                label={el.label}
              />
            );
          })}
        </RadioGroup>
        {form.errors[field.name] && form.touched[field.name] ? (
          <FormHelperText className="mt-2">{form.errors[field.name]}</FormHelperText>
        ) : null}
      </FormControl>
    </Fragment>
  );
};
export const Gateways = ({ field, form, options, autoSelect, ...props }) => {
  // it's used to auto select first gateway option
  useEffect(() => {
    if (autoSelect) {
      form.setFieldValue(field.name, options[0]?.value);
    }
  }, []);

  useEffect(() => {
    if (form.isSubmitting) {
      form.setFieldTouched(field.name, true);
    }
  }, [form?.isSubmitting]);

  const hasError = Boolean(form.errors[field.name] && form.touched[field.name]);

  return (
    <Fragment>
      <Grid container spacing={options?.length > 0 ? 1 : 0}>
        {options?.length > 0 ? (
          <Fragment>
            {options?.map((el, i) => {
              const checked = form.values[field.name] === el.value;
              return (
                <Grid key={i} item>
                  <Badge
                    invisible={!checked}
                    badgeContent={
                      <Fragment>
                        <div className="py-1">
                          <CheckIcon style={{ fontSize: 15 }} />
                        </div>
                      </Fragment>
                    }
                    color="primary"
                  >
                    <Button
                      className={`gateway-option ${checked ? 'gateway-option-active' : ''}`}
                      color="primary"
                      onClick={() => {
                        if (!checked) {
                          form.setFieldValue(field.name, el.value);
                        }
                      }}
                      {...props}
                    >
                      {el.label}
                    </Button>
                  </Badge>
                </Grid>
              );
            })}
          </Fragment>
        ) : (
          <div
            className={`text--gray--dark-xx h6 b2b-main-alert reserve-no-gateway w-100 ${
              hasError ? 'with-radius border border-danger' : ''
            }`}
          >
            <FormattedMessage id="payment.no-gateway" />
          </div>
        )}
      </Grid>
      {hasError && options?.length > 0 ? (
        <FormHelperText className="mt-2 text-danger">{form.errors[field.name]}</FormHelperText>
      ) : null}
    </Fragment>
  );
};

const FieldSet = ({ children, label }) => {
  return (
    <Fragment>
      <Typography variant="caption" component="label">
        {label}
      </Typography>
      {children}
    </Fragment>
  );
};

const selectOptionsCreator = (options, formattedMessage) => {
  const optionsToSet = [];
  if (options.findIndex(el => el.value === 'all') === -1) {
    optionsToSet.push({
      value: 'all',
      label: formattedMessage({ id: 'support.list.filter.all' }),
      default: true,
    });
  }
  optionsToSet.push(...options);
  return optionsToSet;
};

export const AdvancedFilterSelection = ({ onChange, options, labelId, ...props }) => {
  const { formatMessage } = useIntl();

  const selectOptions = selectOptionsCreator(options, formatMessage);

  return (
    <FieldSet label={formatMessage({ id: labelId })}>
      <div className="mt-2">
        <Select
          input={<InputBase className="grayBg filterSelect" variant="filled" />}
          style={{ width: '100%' }}
          onChange={e => {
            onChange(e.target.value);
          }}
          defaultValue={selectOptions.find(option => option.default) ?? selectDefaultItem}
          renderValue={renderValue => (
            <MenuItem>
              <span>{selectOptions.find(item => item.value === renderValue.value)?.label}</span>
            </MenuItem>
          )}
          IconComponent={KeyboardArrowDownTwoToneIcon}
          {...props}
        >
          {selectOptions.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FieldSet>
  );
};
