import { Api, IRequestOptions } from '@hotelian/utils/ApiHandler/ApiInstance';
import api from '@hotelian/constants/api';
import { IGetDestinationRecommendationParams } from '@hotelian/config/interfaces';
import DestinationRecommendation from '@hotelian/utils/models/General/DestinationRecommendation';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import CaptchaModel from './Captcha/captcha.model';

export default class General {
  static async getBaseData() {
    return ResponseHandler.freeHandling(
      Api.call({ url: api.general.getTypes, params: { 'category[]': 'all' } }),
      res => res,
      err => err
    );
  }

  static async getDestinationRecommendation(params?: IGetDestinationRecommendationParams) {
    const request = Api.call<DestinationRecommendation>({
      url: api.general.destinationRecommendation,
      params,
    });
    return await ResponseHandler.freeHandling<DestinationRecommendation>(
      request,
      // res => DestinationRecommendation.create(res),
      err => err
    );
  }
  static async getCaptcha(requestOptions?: IRequestOptions) {
    return ResponseHandler.handle(Api.call({ url: api.captcha.new }, requestOptions), CaptchaModel);
  }
}
