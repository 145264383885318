import React, { useEffect } from 'react';

import usePageVisibility from './usePageVisibility';
import { BrowserStorage } from '@hotelian/helpers/storageHelpers';

interface IBaseTimeout {
  seconds?: number;
  minutes?: number;
  hours?: number;
}

interface IBaseTimeoutSecondsRequired extends IBaseTimeout {
  seconds: number;
}

interface IBaseTimeoutMinutesRequired extends IBaseTimeout {
  minutes: number;
}

interface IBaseTimeoutHoursRequired extends IBaseTimeout {
  hours: number;
}

interface IUseIntervalParams {
  callbackFn: () => any;
  timeout: IBaseTimeoutHoursRequired | IBaseTimeoutMinutesRequired | IBaseTimeoutSecondsRequired;
  storage: BrowserStorage;
  options?: { runAtFirstRender?: boolean };
}
let refresher: NodeJS.Timer;

const useInterval = ({
  callbackFn,
  timeout: { seconds = 0, minutes = 0, hours = 0 },
  storage,
  options = { runAtFirstRender: false },
}: IUseIntervalParams) => {
  const isVisible = usePageVisibility();

  let timeoutPeriod = seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;
  const handleRetrying = () => {
    let currentDate = Date.now();
    let savedTime = storage.get();
    if (savedTime && parseInt(savedTime) < currentDate) {
      callbackFn();
    }
    clearInterval(refresher);
    refresher = setInterval(async () => {
      await storage.set(Date.now() + timeoutPeriod);
      callbackFn();
    }, timeoutPeriod);
  };

  useEffect(() => {
    storage.set(Date.now() + timeoutPeriod);
    if (options?.runAtFirstRender) {
      callbackFn();
    }
    return () => {
      clearInterval(refresher);
      storage.remove();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      handleRetrying();
    } else {
      clearInterval(refresher);
    }
  }, [isVisible]);

  return {};
};

export default useInterval;
