import { useRef, useEffect } from 'react';

/**
 * @description storing the previous value of a state
 * @param state
 * @returns previous value of a state
 */
function usePrevious<T = any>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
