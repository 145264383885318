import { FormattedMessage } from 'react-intl';

// Set "default: true" to change app default currency
const currencies = [
  {
    title: <FormattedMessage id="currencies.usd" />,
    value: 'USD',
    important: true,
    default: true,
  },
  {
    title: <FormattedMessage id="currencies.eur" />,
    value: 'EUR',
    important: true,
  },
  {
    title: <FormattedMessage id="currencies.aed" />,
    value: 'AED',
    important: true,
  },
  {
    title: <FormattedMessage id="currencies.gbp" />,
    value: 'GBP',
    important: true,
  },
  {
    title: <FormattedMessage id="currencies.aud" />,
    value: 'AUD',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.irr" />,
    value: 'IRR',
    important: true,
  },
  {
    title: <FormattedMessage id="currencies.cny" />,
    value: 'CNY',
    important: true,
  },
  {
    title: <FormattedMessage id="currencies.sar" />,
    value: 'SAR',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.chf" />,
    value: 'CHF',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.try" />,
    value: 'TRY',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.rub" />,
    value: 'RUB',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.omr" />,
    value: 'OMR',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.inr" />,
    value: 'INR',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.ars" />,
    value: 'ARS',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.azn" />,
    value: 'AZN',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.bhd" />,
    value: 'BHD',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.brl" />,
    value: 'BRL',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.bng" />,
    value: 'BNG',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.cad" />,
    value: 'CAD',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.cop" />,
    value: 'COP',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.czk" />,
    value: 'CZK',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.dkk" />,
    value: 'DKK',
    important: false,
  },
  {
    title: <FormattedMessage id="currencies.egp" />,
    value: 'EGP',
    important: false,
  },
];

export default currencies;
