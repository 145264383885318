import { defaultAppTimezone } from '@hotelian/constants/defaultValues';
import moment from 'moment/moment';
import jMoment from 'jalali-moment';
import { MomentTypes } from '@hotelian/config/interfaces';

declare const self: ServiceWorkerGlobalScope;

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

type Projects = 'b2b' | 'b2c';

export class DateParser {
  project: Projects | undefined = undefined;

  constructor(project: Projects) {
    this.project = project;
  }

  /**
   * Get the current time zone relative to Greenwich in millisecond
   * @author    HotelianCom
   * @returns   {Number} current time zone
   */
  getCurrentTimezone = () => {
    const d = new Date();
    return d.getTimezoneOffset() * 60 * 1000 * -1;
  };
  /**
   * Get difference between current time zone and app default time zone millisecond
   * @author    HotelianCom
   * @returns   {Number}
   */
  getDifferenceBetweenCurrentAndDefaultTimezone = () => {
    return this.getCurrentTimezone() - defaultAppTimezone;
  };
  /**
   * Get timezone localtion ex) America/Los_Angeles
   * @author    HotelianCom
   * @returns   {String}
   */
  getTimezoneLocation = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
  /**
   * Get final formatted date
   * @author    HotelianCom
   * @param     {string} date initial date
   * @param     {string} dateFormat initial date format
   * @param     {string} momentType application moment type
   * @param     {string} gregorianFinalFormat gregorian final format
   * @param     {string} jalaliFinalFormat jalali final format
   * @param     {object} parseDate function's options
   * @returns   {Number}
   */
  parseDate = (
    date: string,
    dateFormat: string,
    gregorianFinalFormat: string,
    jalaliFinalFormat?: string,
    momentType: MomentTypes = 'moment_js',
    { justFormatDate }: { justFormatDate?: boolean } = { justFormatDate: false }
  ) => {
    /*// error handling
    if (!date || !momentType || !gregorianFinalFormat || !jalaliFinalFormat) {
      throw new Error('Pass all parameters in parseDate() method');
    }*/
    const dateInTimeStamp = moment(date, dateFormat).unix() * 1000; // millisecond
    if (this.project === 'b2b') {
      return moment(dateInTimeStamp).format(gregorianFinalFormat);
    } else if (this.project === 'b2c') {
      const finalDateInTimeStamp = justFormatDate
        ? dateInTimeStamp
        : this.getDifferenceBetweenCurrentAndDefaultTimezone() + dateInTimeStamp;
      if (momentType === 'moment_jalali') {
        return jMoment(finalDateInTimeStamp).format(jalaliFinalFormat);
      } else {
        return moment(finalDateInTimeStamp).format(gregorianFinalFormat);
      }
    }
  };
}

export const handleMsgBySw = (msg: any) => {
  self.clients
    .matchAll({
      type: 'window',
      includeUncontrolled: true,
    })
    .then(all => {
      all.forEach(client => {
        client.postMessage(msg);
      });
    });
};
