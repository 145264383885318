import React, { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import cookie from 'cookie';

import logo from '@hotelian/assets/hotelian-logo.svg';
import { documentationUrl, gb2bWebsiteUrl } from '@hotelian/constants/defaultValues';
import { UIContext, LanguageContext } from 'contexts';
import CreditIcons from '@hotelian/components/CreditIcons';
import contact from '@hotelian/constants/contact';
import ExternalLink from '@hotelian/components/common/ExternalLink';
import { isCorporate } from '../helpers';

const Footer = ({ mobileMode }) => {
  const { footerHolder } = useContext(UIContext);
  const { direction } = useContext(LanguageContext);

  const cookies = cookie.parse(document.cookie);
  const userCountryCode = cookies['B2B_AGENCY_COUNTRY_CODE'];

  const mainEmail = contact.emails.find(el => el.main);

  return (
    <footer className="b2b-footer">
      <div className={`content__wrapper d-flex align-items-center ${mobileMode ? 'flex-wrap' : ''}`}>
        <div className={`${mobileMode ? 'col-12 p-0 mb-2' : 'col-3 pl-0'} h3`}>
          <img src={logo} alt="hotelian-logo" style={{ width: 180 }} className="h-auto" />
        </div>
        <div className={`${mobileMode ? 'col-12 p-0' : 'col-9 pr-0'}`}>
          <div className={`d-flex ${mobileMode ? 'flex-wrap' : ''}`}>
            {!isCorporate() ? (
              <Fragment>
                <div className={`${mobileMode ? 'col-6 p-0' : 'col-4'}`}>
                  <div className="text-muted py-1">
                    <ExternalLink href={`${gb2bWebsiteUrl}/contact-us`} target="_blank">
                      <FormattedMessage id="all.contact-us" />
                    </ExternalLink>
                  </div>
                  <div className="text-muted py-1">
                    <ExternalLink href={`${gb2bWebsiteUrl}/registration`} target="_blank">
                      <FormattedMessage id="all.registration" />
                    </ExternalLink>
                  </div>
                  <div className="text-muted py-1">
                    <ExternalLink href={`${documentationUrl}`} target="_blank">
                      <FormattedMessage id="all.documentation" />
                    </ExternalLink>
                  </div>
                </div>
                <div className={`${mobileMode ? 'col-6 p-0' : 'col-4'}`}>
                  <div className="text-muted py-1">
                    <ExternalLink href={`${gb2bWebsiteUrl}/terms-and-conditions`} target="_blank">
                      <FormattedMessage id="footer.links.terms-and-conditions" />
                    </ExternalLink>
                  </div>
                  <div className="text-muted py-1">
                    <ExternalLink href={`${gb2bWebsiteUrl}/privacy-policy`} target="_blank">
                      <FormattedMessage id="footer.links.privacy-policy" />
                    </ExternalLink>
                  </div>
                </div>
              </Fragment>
            ) : null}

            <div className={`${mobileMode ? 'col-6 p-0' : 'col-4'}`}>
              <div className="make-ltr">
                <div className={`text-muted py-1 ${direction === 'rtl' ? 'text-right' : ''}`}>
                  <a href={`tel:${contact.london.phones[0]}`}>{contact.london.phones[0]}</a>
                  {userCountryCode === 'AAA' && (
                    <a className="d-block mt-1" href={`tel:+98 21 72812`}>
                      +98 21 72812
                    </a>
                  )}
                </div>
              </div>
              {mainEmail && (
                <div className="text-muted py-1">
                  <a href={`mailto:${mainEmail.email}`}>{mainEmail.email}</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {footerHolder ? <div style={{ height: Number(footerHolder) }} /> : null}
      <div className="b2b-footer-copy-right">
        <div className={`content__wrapper d-flex justify-content-between ${mobileMode ? 'flex-column' : ''}`}>
          <span className="text-muted">
            <FormattedMessage id="footer.copy-right" />
          </span>
          <div className={mobileMode ? 'mt-3' : undefined}>
            <CreditIcons />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
