import { IActionFireOptions, IActionStrategy, IStrategyEvent } from './broadcastStrategy.interfaces';
import ServiceWorkerObserver from '../Observers/ServiceWorker.observer';

export default class BroadcastStrategy<EventNames> {
  strategy: IActionStrategy<EventNames> = ServiceWorkerObserver.getInstance();
  prevStrategy: IActionStrategy<EventNames> | undefined;
  observers: IStrategyEvent<EventNames>[] = [];
  tabId: string | undefined;
  private static instance: BroadcastStrategy<any>;

  setTabId(tabId: string) {
    this.tabId = tabId;
    this.strategy.tabId = tabId;
  }

  static getInstance<T>(): BroadcastStrategy<T> {
    if (!BroadcastStrategy.instance) {
      BroadcastStrategy.instance = new BroadcastStrategy<T>();
    }

    return BroadcastStrategy.instance;
  }

  get canHandleCrossTab() {
    return this.strategy.canHandleCrossTab;
  }

  setStrategy(strategy: IActionStrategy<EventNames>) {
    this.prevStrategy = this.strategy;
    strategy.observers = this.observers;
    this.strategy = strategy;
    return this;
  }

  registerListener() {
    this.strategy.registerListener();
    return this;
  }
  fire(
    { name, payload }: { name: EventNames; payload?: IStrategyEvent<EventNames>['payload'] },
    { crossTab = true }: IActionFireOptions = {}
  ) {
    this.strategy.fire({ name, payload: { tabId: this.tabId, ...payload } }, { crossTab });
    return this;
  }

  subscribe(event: Omit<IStrategyEvent<EventNames>, 'payload'>) {
    const prevEvents =
      this.observers.length > 0 ? this.observers.filter(item => item.name !== event.name) : this.observers;
    this.observers = [...prevEvents, event];
    this.strategy.observers = this.observers;
    return this;
  }

  unsubscribe(event: IStrategyEvent<EventNames>['name']) {
    this.observers = this.observers.filter(item => item.name === event);
    this.strategy.observers = this.observers;
    return this;
  }
}
