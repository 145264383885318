import React, { Fragment, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircleInfo } from '@fortawesome/pro-light-svg-icons';

import { levels } from 'constants/userTypes';
import { AuthContext } from 'contexts';
import CustomTooltip from '@hotelian/components/CustomTooltip';
import { FormattedMessage } from 'react-intl';
import PriceComponent from '@hotelian/components/PriceComponent';

const UserCreditComponent = ({ price, currency, tooltipTitle }) => (
  <div className="text-direction b2b-user-profile-credit font-weight-bold d-flex">
    <span>
      <PriceComponent price={price} currency={currency} />
    </span>
    <CustomTooltip title={tooltipTitle}>
      <span className="ml-1">
        <FontAwesomeIcon icon={faCircleInfo} size="lg" fixedWidth />
      </span>
    </CustomTooltip>
  </div>
);

const UserDetailsCmp = ({ btnMode }) => {
  const { user } = useContext(AuthContext);

  const level = levels.find(el => el.value === user?.agency?.level);
  const currency = user?.agency?.currency;

  return (
    <div className="d-flex align-items-center flex-nowrap">
      <div className="mr-3 position-relative">
        <img
          style={{ height: 36, width: 36 }}
          className="object-fit-cover with-radius"
          src={Boolean(user.profile_pic) ? user.profile_pic : '/images/no-avatar.png'}
          alt="profile-pic"
        />
        <CustomTooltip title={level.label}>
          <img className="b2b-user-profile-level" src={level.icon} alt={level.value} />
        </CustomTooltip>
      </div>
      <div className={`${btnMode ? 'mr-3' : 'overflow-hidden'}`}>
        <div className={`${btnMode ? 'mb-n1' : 'text-nowrap text-truncate'} text-nowrap h6 text-direction`}>
          {user.first_name ?? '-'} {user.last_name ?? '-'}
        </div>
        <UserCreditComponent
          price={user?.agency?.cash_credit}
          currency={currency}
          tooltipTitle={
            <Fragment>
              <span className="d-block large">
                <FormattedMessage id="nav.profile.cash-credit" />
              </span>
              <FormattedMessage id="profile.cash-credit.description" />
            </Fragment>
          }
        />
        <UserCreditComponent
          price={user?.agency?.store_credit}
          currency={currency}
          tooltipTitle={
            <Fragment>
              <span className="d-block large">
                <FormattedMessage id="nav.profile.store-credit" />
              </span>
              <FormattedMessage id="profile.store-credit.description" />
            </Fragment>
          }
        />
      </div>
      {btnMode ? (
        <div>
          <FontAwesomeIcon icon={faAngleDown} className="fa-lg" />
        </div>
      ) : null}
    </div>
  );
};

export default UserDetailsCmp;
