import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/pro-light-svg-icons';

import useReactRouter from 'utils/hooks/useReactRouter';
import { TextInput } from '@hotelian/components/Inputs';
import Captcha from 'components/Captcha';
import { loginSchema, corporateLoginSchema } from './_validationSchema';
import { AuthContext } from 'contexts';
import usePrevious from '@hotelian/hooks/usePrevious';
import { removeToken } from 'utils';
import responseValidationErrorHandler from '@hotelian/helpers/responseValidationErrorHandler';
import { useLoadingContext } from 'utils/hooks/contextHooks';
import urls from 'constants/urls';
import { isCorporate } from 'helpers';

const LoginForm = ({ initialValues, isModal, afterLogin, newCaptcha, withRedirectToLastPlace }) => {
  const { formatMessage } = useIntl();
  const { loading, setLoading } = useLoadingContext();
  const { signin, setOpenSessionExpiredModal } = useContext(AuthContext);

  const { navigate } = useReactRouter();

  const [revalidateCaptcha, setRevalidateCaptcha] = useState(false);

  const getNewCaptcha = () => {
    setRevalidateCaptcha(prevState => !prevState);
  };

  const prevNewCaptcha = usePrevious(newCaptcha);

  useEffect(() => {
    if (newCaptcha === true && prevNewCaptcha !== undefined) {
      getNewCaptcha();
    }
  }, [newCaptcha]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSubmit = async (values, { setFieldError, setFieldValue, setFieldTouched }) => {
    return signin(values, setFieldError, {
      goToHome: !isModal,
      onCatch: () => {
        setFieldTouched('captcha_value', false);
      },
      redirectToLastPlace: withRedirectToLastPlace,
    })
      .then(res => {
        if (!res) {
          getNewCaptcha();
          setFieldValue('captcha_value', '');
        } else {
          if (typeof afterLogin === 'function') {
            afterLogin(res);
          }
        }
        return res;
      })
      .catch(err => {
        getNewCaptcha();
        setFieldValue('captcha_value', '');
        responseValidationErrorHandler(err, null, true);
      });
  };

  const isCorporateActive = isCorporate();

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        email: initialValues?.email ?? '',
        username: initialValues?.username ?? '',
        password: '',
        captcha_token: '',
        captcha_value: '',
      }}
      validationSchema={isCorporateActive ? corporateLoginSchema : loginSchema}
    >
      {({ setFieldValue }) => (
        <Form>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  name={isCorporateActive ? 'email' : 'username'}
                  component={TextInput}
                  placeholder={formatMessage({
                    id: isCorporateActive ? 'login.email.placeholder' : 'login.username.placeholder',
                  })}
                  startAdornment={
                    <Fragment>
                      <FontAwesomeIcon
                        icon={faUser}
                        className={`mr-3 ${isModal ? 'text-muted' : 'color-theme'}`}
                        style={{ opacity: isModal ? 0.5 : undefined }}
                      />
                    </Fragment>
                  }
                  disabled={isModal}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="password"
                  component={TextInput}
                  type="password"
                  placeholder={formatMessage({
                    id: 'login.password.placeholder',
                  })}
                  startAdornment={<FontAwesomeIcon icon={faLock} className="mr-3 color-theme" />}
                  autoFocus={isModal}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item className="flex-grow-1">
                    <Field
                      name="captcha_value"
                      component={TextInput}
                      placeholder={formatMessage({
                        id: 'login.captcha.placeholder',
                      })}
                    />
                  </Grid>

                  <Grid item>
                    <Captcha
                      key={revalidateCaptcha}
                      setToken={t => {
                        setFieldValue('captcha_token', t);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  disabled={loading}
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className="w-100 d-block"
                >
                  <FormattedMessage id="login.login" />
                </Button>
              </Grid>
              {isModal && (
                <Grid item xs={12} className="text-center color-theme mt-3 mb-2">
                  <button
                    className="bg-transparent border-none color-theme hover-underline"
                    onClick={() => {
                      setOpenSessionExpiredModal(false);
                      removeToken();
                      navigate(urls.user.login);
                    }}
                    disabled={loading}
                  >
                    <FormattedMessage id="login.user-idle-modal.switch-account" />
                  </button>
                </Grid>
              )}
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
