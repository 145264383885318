import { AxiosError } from 'axios';

import Request from '@hotelian/utils/ApiHandler/Request';
import getCurrentLocale from 'locales/getCurrentLocale';
import { getToken } from 'utils';
import getCurrency from 'helpers/getCurrency';
import BroadcastStrategy from '@hotelian/utils/Strategy/BroadcastStrategy';
import { IUIActionNames } from './interfaces';
import api from '@hotelian/constants/api';
// @ts-ignore
import Swal from '@hotelian/components/Swal';

const observer = BroadcastStrategy.getInstance<IUIActionNames>();

const variableLocale = () => getCurrentLocale()?.locale;
const variableCurrency = () => getCurrency()?.value;

const ignoredTimeoutNotice = [api.hotels.fullOptionCheck, api.reservation.book];

export const Instance = Request.create();
observer.subscribe({
  name: 'REMOVE_FAILED_QUEUE_ARRAY',
  callback: () => {
    Instance.failedRequestQueue = [];
  },
});
Instance.setBaseUrl(process.env.REACT_APP_BACKEND_URL as string);
Instance.setHeaders({
  'X-Accepted-Platform': 'B2B Site',
  'Content-Type': 'application/json',
});

Instance.setLanguage(variableLocale() as string);
Instance.setCurrency(variableCurrency() as string);

if (getToken()) {
  Instance.setHeaders({
    Authorization: `${'Bearer ' + getToken()}`,
  });
}

Instance.setResponseInterceptor(async (err: AxiosError) => {
  let backendErrorMessage = '';
  if (err.response) {
    const { result } = err.response?.data;
    const messageObj = result?.[0] ? result[0] : result;
    backendErrorMessage = messageObj?.message ?? '';
  } else if (err.request) {
    // timeout error
    backendErrorMessage = 'Error connecting to server!';
    if (!ignoredTimeoutNotice.some(url => url === err.config.url)) {
      Swal.fire({
        key: undefined,
        props: undefined,
        type: undefined,
        html: 'Error connecting to server!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Reload',
      }).then((result: any) => {
        if (window && result.value) {
          window?.location?.reload();
        }
      });
    }
  }
  return Promise.reject({ ...err, backendErrorMessage });
});

export default Instance.requestInstance;
