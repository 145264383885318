import { useEffect, useState } from 'react';

import { currentSearchStorage } from '@hotelian/helpers/storageHelpers';

export interface IUseCurrentSearchValues {
  setCurrentSearch: (values: any) => void;
  currentSearch: any;
  removeCurrentSearch: () => void;
  compare: (firstObject: any, secondObject: any) => boolean;
}

const compare: IUseCurrentSearchValues['compare'] = (firstObject, secondObject) => {
  if (firstObject?.destination?.dest_type !== secondObject?.destination?.dest_type) {
    return false;
  } /*else if (search.nationality !== currentSearch?.nationality?.code) {
      // removeCurrentSearch();
    }*/ else if (secondObject?.checkin !== firstObject?.checkin) {
    return false;
  } else if (secondObject?.checkout !== firstObject?.checkout) {
    return false;
  } else if (firstObject?.rooms?.length !== secondObject?.rooms?.length) {
    return false;
  } else {
    firstObject?.rooms?.forEach((room: any, index: number) => {
      if (Number(room.adults) !== Number(secondObject?.rooms?.[index]?.adults)) {
        return false;
      } else if (room?.children?.length !== secondObject?.rooms?.[index]?.children?.length) {
        return false;
      }
      room?.children?.forEach((child: any, childIndex: number) => {
        if (Number(child) !== Number(secondObject?.rooms?.[index]?.children?.[childIndex])) {
          return false;
        }
      });
    });
  }
  return true;
};

const useCurrentSearchValues = (): IUseCurrentSearchValues => {
  const [currentSearchValues, setCurrentSearchValues] = useState(currentSearchStorage.get());

  const removeCurrentSearch = () => {
    setCurrentSearchValues(null);
    currentSearchStorage.remove();
  };
  const setCurrentSearch = async (values: any) => {
    setCurrentSearchValues(values);
    currentSearchStorage.set(values);
  };

  useEffect(() => {
    window.addEventListener('popstate', removeCurrentSearch);
    return () => window.removeEventListener('popstate', removeCurrentSearch);
  }, []);

  return { setCurrentSearch, currentSearch: currentSearchValues, removeCurrentSearch, compare };
};

export default useCurrentSearchValues;
