import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { SetState } from '@hotelian/config/interfaces';

interface IMessageType {
  title?: string;
  message?: string;
}

interface IError403Dialog {
  open: boolean;
  setOpen: SetState<boolean>;
  messages: IMessageType;
  setMessages: SetState<IMessageType>;
}

const Error403Dialog: React.FC<IError403Dialog> = ({ open, setOpen, messages, setMessages }) => {
  const handleClose = () => {
    setOpen(false);
    setMessages({});
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle className="bg-theme-secondary text-white">{messages?.title ?? '--'}</DialogTitle>
      <DialogContent>
        <div className="py-3">
          <DialogContentText>{messages?.message ?? '--'}</DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose} className="px-4" variant="outlined">
          <FormattedMessage id="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Error403Dialog;
