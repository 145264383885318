import React, { useContext, useEffect, useState } from 'react';

import { LanguageContext, LoadingContext } from '.';
import ThemeProvider from '@hotelian/config/ThemeProvider';
import getCurrentLocale from 'locales/getCurrentLocale';
import usePrevious from '@hotelian/hooks/usePrevious';
import usePageVisibility from '@hotelian/hooks/usePageVisibility';
import { userLocaleStorage } from '@hotelian/helpers/storageHelpers';
import { Instance } from 'utils/AxiosInstance';

const LanguageContextProvider = ({ children }) => {
  const { setLoading } = useContext(LoadingContext);

  const [localeObj, setLocaleObj] = useState(getCurrentLocale());
  const [direction, setDirection] = useState(getCurrentLocale()?.direction ?? 'ltr');
  const [locale, setLocale] = useState(getCurrentLocale().locale);
  const isVisible = usePageVisibility();

  const body = document.querySelector('body');
  const prevLocale = usePrevious(localeObj);

  const handleChangeLanguage = () => {
    const locale = getCurrentLocale();
    setLocaleObj(locale);
    setDirection(locale.direction);
    setLocale(locale.locale);
    Instance.setLanguage(locale.locale);
  };

  useEffect(() => {
    if (prevLocale) {
      body.classList.replace(prevLocale.direction, localeObj.direction);
      body.classList.replace(`${prevLocale.locale}_lang`, `${localeObj.locale}_lang`);
    } else {
      body.classList.add(direction);
      body.classList.add(`${locale}_lang`);
    }
  }, [localeObj]);

  useEffect(() => {
    if (localeObj !== getCurrentLocale() && isVisible) {
      handleChangeLanguage();
    }
  }, [isVisible]);

  const changeLang = async (l, toggleModal) => {
    try {
      await userLocaleStorage.set(l);
      handleChangeLanguage();
    } catch (e) {
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  return (
    <LanguageContext.Provider value={{ localeObj, locale, direction, changeLang }}>
      <ThemeProvider direction={direction} messages={localeObj.messages} locale={locale}>
        {children}
      </ThemeProvider>
    </LanguageContext.Provider>
  );
};
export default LanguageContextProvider;
