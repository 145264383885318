import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from '@hotelian/assets/hotelian-logo-topbar.svg';
import LanguageSwitcher from 'components/LanguageSwitcher';
// import CurrencySwitcher from 'components/CurrencySwitcher';
import { isMultiLanguage } from 'constants/default-values';

const MobileTopbar = ({ setOpen, logoUrl, withLanguageSwitcher, withCurrencySwitcher }) => {
  return (
    <header className="b2b-header">
      <div>
        <div className="content__wrapper position-relative h-100 d-flex align-items-center flex-nowrap justify-content-between">
          <div className="flex-grow-1 h-100 d-flex align-items-center">
            <div className="mr-3">
              <IconButton
                size="small"
                className="text-white"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div>
              <Link to={logoUrl}>
                <img src={logo} alt="hotelian-logo" style={{ height: 16 }} className="w-auto" />
              </Link>
            </div>
          </div>
          <div className="h-100 d-flex align-items-center">
            {withLanguageSwitcher && isMultiLanguage ? (
              <div className="ml-2">
                <LanguageSwitcher smallButton />
              </div>
            ) : null}
            {/*{withCurrencySwitcher ? (
              <div className="ml-2">
                <CurrencySwitcher mobileMode />
              </div>
            ) : null}*/}
          </div>
        </div>
      </div>
    </header>
  );
};

export default MobileTopbar;
