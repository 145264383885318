const { thisMoment } = require('@hotelian/utils/CalendarUtils');
const momentTZ = require('moment-timezone');

export const websiteUrl = 'https://www.hotelian.com';
export const gb2bWebsiteUrl = 'https://b2b.hotelian.com';
export const imageQualities = ['small', 'medium', 'large', 'thumb'];
export const searchExpirationTime = 20 * 60; // (seconds;
export const intellisenseDelayTime = 500; // (milliseconds;
export const b2bIntellisenseDelayTime = 200; // (milliseconds;
export const defaultAppTimezone = momentTZ().tz('Europe/London').utcOffset() * 60 * 1000; // (milliseconds) (london time zone;
export const defaultAppLocale = 'Europe/London';
export const updateUserInfoTimeout = 1 * 60 * 60 * 1000; // (milliseconds;
export const showVerifyNotificationTimeout = 2 * 60 * 60 * 1000; // (milliseconds;
export const cookiesExpireTime = new Date(thisMoment() + 90 * 24 * 60 * 60 * 1000); // (milliseconds) (3 months;
export const captchaExpiresAfter = 60 * 15 * 1000; // (milliseconds;
export const twitterCreator = '@';
export const appStoreApplicationUrl = '#';
export const googlePlayApplicationUrl = '#';
export const b2bPrefix = '/app';
export const b2bGeneralPrefix = '/general';
export const documentationUrl = 'https://b2b.hotelian.com/documentation';
export const redirectStatusCode = 302;
export const companyCoordinates = { lat: 25.2048, lng: 55.2708 };
export const social = {
  instagram: 'https://www.instagram.com/hotelian_official/',
  linkedin: 'https://www.linkedin.com/company/hotelianco/',
};
export const b2bAbsoluteUrl = process.env.NEXT_PUBLIC_B2B_ABSOLUTE_URL;

export const getStripeImageURL = (backendURL: string) => {
  const urlSplitBySlash = backendURL.split('/');
  return `${urlSplitBySlash.splice(0, urlSplitBySlash.length - 1).join('/')}/static/images/stripe-gateway-long.png`;
};

export const captchaExpirationTimeout = 10; // minutes;

export const allowedHTMLTagsToParse = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'span',
  'br',
  'strong',
  'bold',
  'blockquote',
  'ul',
  'ol',
  'li',
  'hr',
  'pre',
  'code',
  'cite',
  'em',
];
