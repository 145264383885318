import UAParser from 'ua-parser-js';
import { NextPageContext } from 'next';
import { isString } from '@hotelian/utils';

export const mobileDetection = (req?: NextPageContext['req']) => {
  const userAgent = req ? req.headers['user-agent'] : typeof navigator !== 'undefined' ? navigator?.userAgent : '';
  const parser = new UAParser();
  if (typeof userAgent === 'string') parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || 'desktop';
  const isMobile = deviceType === 'mobile';
  const isTablet = deviceType === 'tablet';
  const isDesktop = !isMobile && !isTablet;
  return { isMobile, isDesktop, isTablet };
};
