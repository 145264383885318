import React, { Fragment } from 'react';

import { commaBetween, isNumber, isObject, isString } from '@hotelian/utils';

interface IPriceComponentProps {
  price: number | string;
  currency: any;
  containerClasses?: string;
  priceClasses?: string;
  currencyClasses?: string;
  colorful?: boolean;
  strikeThrough?: boolean;
}

const PriceComponent: React.FC<IPriceComponentProps> = ({
  price,
  currency,
  containerClasses = '',
  priceClasses = '',
  currencyClasses = null,
  colorful = false,
  strikeThrough = false,
}) => {
  const content = (
    <Fragment>
      <span className={priceClasses ?? undefined}>
        {isNumber(price) ? commaBetween(price, { maximumFractionDigits: 2 }) : '--'}
      </span>{' '}
      <span className={currencyClasses ?? undefined}>
        {isString(currency) ? currency : isObject(currency) && currency?.content ? currency.content : '--'}
      </span>
    </Fragment>
  );
  return (
    <div
      className={` ${containerClasses ?? ''} ${
        colorful && price > 0 ? 'text-success' : colorful && price < 0 ? 'text-danger' : ''
      }`}
      dir="auto"
    >
      {strikeThrough ? <del>{content}</del> : content}
    </div>
  );
};

export default PriceComponent;
