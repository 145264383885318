import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import urls from '../constants/urls';

const NotFoundDialog = ({ open, setOpen }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle className="bg-theme-secondary text-white">
        <FormattedMessage id="404-dialog.title" />
      </DialogTitle>
      <DialogContent>
        <div className="py-3">
          <DialogContentText>
            <FormattedMessage id="404-dialog.description" />
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Link to={urls.app.view}>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FormattedMessage id="404-dialog.back-to-home" />
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default NotFoundDialog;
