import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

export const replyTicketSchema = Yup.object().shape({
  text: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .min(2, <FormattedMessage id="validation.min-2-char" />),
});

export const addIpToServiceSchema = Yup.object().shape({
  ip: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .min(2, <FormattedMessage id="validation.min-2-char" />)
    .matches(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Invalid Ip'),
});

export const addNewTicketSchema = Yup.object().shape({
  subject: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  text: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  department: Yup.string().required(<FormattedMessage id="validation.required" />),
  priority: Yup.string().required(<FormattedMessage id="validation.required" />),
  service: Yup.string().required(<FormattedMessage id="validation.required" />),
  reservation_ref: Yup.string().required(<FormattedMessage id="validation.required" />),
});

export const cancelBookingSchema = Yup.object().shape({
  note: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
});

export const increasePriceSchema = Yup.object().shape({
  percent: Yup.number()
    .required(<FormattedMessage id="validation.required" />)
    .min(0, <FormattedMessage id="validation.invalid-increase-percent" />)
    .max(300, <FormattedMessage id="validation.invalid-increase-percent" />),
});

export const UserProfileSchema = Yup.object().shape({
  phone_number: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(4, <FormattedMessage id="validation.min-4-phone" />)
    .max(32, <FormattedMessage id="validation.max-32-phone" />),
  phone_prefix: Yup.string().required(<FormattedMessage id="validation.required" />),
  first_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  last_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  email: Yup.string()
    .email(<FormattedMessage id="validation.email" />)
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  // nationality: Yup.string().required(
  //   <FormattedMessage id="validation.required" />
  // ),
});

export const AgencyProfileSchema = Yup.object().shape({
  phone: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(4, <FormattedMessage id="validation.min-4-phone" />)
    .max(32, <FormattedMessage id="validation.max-32-phone" />),
  address: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(128, <FormattedMessage id="validation.max-128" />),
  email: Yup.string()
    .email(<FormattedMessage id="validation.email" />)
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  website: Yup.string()
    // .url(<FormattedMessage id="validation.url" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  city_id: Yup.number(),
});

export const ResetPasswordSchema = Yup.object().shape({
  current_password: Yup.string().required(<FormattedMessage id="validation.required" />),
  new_password: Yup.string().required(<FormattedMessage id="validation.required" />),
  confirm_new_password: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .oneOf([Yup.ref('new_password'), null], <FormattedMessage id="profile.password.match" />),
});

export const AgencyAddUserSchema = Yup.object().shape({
  username: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(32, <FormattedMessage id="validation.max-32" />)
    .matches(/^[a-zA-Z_]+$/, 'Invalid username. just english alphabet')
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  email: Yup.string()
    .email(<FormattedMessage id="validation.email" />)
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  phone_number: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(4, <FormattedMessage id="validation.min-4-phone" />)
    .max(16, <FormattedMessage id="validation.max-16-phone" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  phone_prefix: Yup.string().required(<FormattedMessage id="validation.required" />),
  is_banned: Yup.bool().required(<FormattedMessage id="validation.required" />),
  first_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  last_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  password_field: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  confirm_password_field: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .oneOf([Yup.ref('password_field'), null], <FormattedMessage id="profile.password.match" />),
});

export const AgencyEditUserSchema = Yup.object().shape({
  username: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(32, <FormattedMessage id="validation.max-32" />)
    .matches(/^[a-zA-Z_]+$/, 'Invalid username. just english alphabet'),
  email: Yup.string()
    .email(<FormattedMessage id="validation.email" />)
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  phone_number: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .min(4, <FormattedMessage id="validation.min-4-phone" />)
    .max(16, <FormattedMessage id="validation.max-16-phone" />),
  phone_prefix: Yup.string().required(<FormattedMessage id="validation.required" />),
  first_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  last_name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .max(64, <FormattedMessage id="validation.max-64" />),
  password_field: Yup.string()
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  confirm_password_field: Yup.string()
    .oneOf([Yup.ref('password_field'), null], <FormattedMessage id="profile.password.match" />)
    .min(6, <FormattedMessage id="validation.min-6-password" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
});

export const IncreaseBalanceSchema = Yup.object().shape({
  amount: Yup.string().required(<FormattedMessage id="validation.required" />),
  gateway: Yup.string().required(<FormattedMessage id="validation.required" />),
});

export const ContactUsSchema = Yup.object().shape({
  subject: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  text: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .min(5, <FormattedMessage id="validation.min-5-char" />),
  name: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
  captcha_value: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />)
    .length(5, <FormattedMessage id="validation.invalid-captcha" />),
  email: Yup.string()
    .required(<FormattedMessage id="validation.required" />)
    .email(<FormattedMessage id="validation.email" />)
    .trim(<FormattedMessage id="validation.invalid-trim" />),
});
