import moment, { MomentFormatSpecification, unitOfTime } from 'jalali-moment';
import { MomentTypes } from '@hotelian/config/interfaces';
class CalendarUtils {
  days = [
    { en: 'Su', fa: 'ی', fullEn: 'Sunday', fullFa: 'یکشنبه' },
    { en: 'Mo', fa: 'د', fullEn: 'Monday', fullFa: 'دوشنبه' },
    { en: 'Tu', fa: 'س', fullEn: 'Tuesday', fullFa: 'سه شنبه' },
    { en: 'We', fa: 'چ', fullEn: 'Wednesday', fullFa: 'چهارشنبه' },
    { en: 'Th', fa: 'پ', fullEn: 'Thursday', fullFa: 'پنجشنبه' },
    { en: 'Fr', fa: 'ج', fullEn: 'Friday', fullFa: 'جمعه' },
    { en: 'Sa', fa: 'ش', fullEn: 'Saturday', fullFa: 'شنبه' },
  ];
  oneDay = 24 * 60 * 60 * 1000;
  oneMonth = 30 * 24 * 60 * 60 * 1000;
  thisMoment = () => Date.now();

  /**
   * get moment formatters by app moment type
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @returns   {Object} moment formatters
   */
  getFormatter = (momentType: MomentTypes) => {
    let yearFormatter: unitOfTime.Base = 'year';
    let monthFormatter: unitOfTime.Base = 'month';
    let dateFormatter: MomentFormatSpecification = 'YYYY/MM/DD';
    let desktopInputFormatter: MomentFormatSpecification = 'DD MMM YYYY';
    if (momentType === 'moment_jalali') {
      yearFormatter = 'jYear';
      monthFormatter = 'jMonth';
      dateFormatter = 'jYYYY/jMM/jDD';
      desktopInputFormatter = 'jYYYY/jMM/jDD';
    }
    return {
      yearFormatter,
      monthFormatter,
      dateFormatter,
      desktopInputFormatter,
    };
  };

  /**
   * get week days
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @returns   {Object[]} week days
   */
  getDays = (momentType: MomentTypes) => {
    const days = [...this.days];
    if (momentType === 'moment_jalali') {
      days.pop();
      days.unshift(this.days[this.days.length - 1]);
    }
    return days;
  };

  /**
   * get week day
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date we need the weekday in timestamp
   * @returns   {string} week day e.g: Sunday or یکشنبه
   */
  getWeekDay = (momentType: MomentTypes, date: string) => {
    const days = [...this.days];
    const day = days[moment(date).weekday()];
    if (momentType === 'moment_jalali') {
      return day?.fullFa;
    }
    return day?.fullEn;
  };

  /**
   * get day
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date we need the day in timestamp
   * @returns   {number} day
   */
  getDay = (momentType: MomentTypes, date: string) => {
    return moment(date).format(momentType === 'moment_jalali' ? 'jDD' : 'DD');
  };

  /**
   * get full month
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date we need the month in timestamp
   * @returns   {number} month e.g: May or اردیبهشت
   */
  getFullMonth = (momentType: MomentTypes, date: string) => {
    return moment(date).format(momentType === 'moment_jalali' ? 'jMMMM' : 'MMMM');
  };

  /**
   * get full date
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date in timestamp
   * @returns   {string} date in YYYY-MM-DD format
   */
  getFullDate = (momentType: MomentTypes, date: string) => {
    return moment(date).format(momentType === 'moment_jalali' ? 'jYYYY-jMM-jDD' : 'YYYY-MM-DD');
  };

  /**
   * get the number of empty space in first of the calendar
   * @author    HotelianCom
   * @argument  {number} showMonth - first day of month that displayed
   * @argument  {string} momentType - moment_js | moment_jalali
   * @returns   {number} number of empty space in first of the calendar
   */
  getFirstMonthDay = (showMonth: number, momentType: MomentTypes) => {
    let weekday = moment(showMonth).weekday();
    if (momentType === 'moment_jalali') {
      weekday++;
      if (weekday > 6) {
        weekday = 0;
      }
    }
    const thisDay = this.getDays(momentType)[weekday];
    return this.getDays(momentType).findIndex(el => el.en === thisDay.en);
  };

  /**
   * get first day of current month
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date of chosen day in unix
   * @returns   {number} first day of current month in timestamp format
   */
  getMonth = (date: string, momentType: MomentTypes) => {
    const year = moment(date)[this.getFormatter(momentType).yearFormatter]();
    // moment().get("month") is zero base
    const month = moment(date).get(this.getFormatter(momentType).monthFormatter) + 1;
    return moment(`${year}/${month}/1`, this.getFormatter(momentType).dateFormatter).unix() * 1000;
  };

  /**
   * get first day of current month
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @returns   {number} first day of current month in timestamp format
   */
  getThisMonth = (momentType: MomentTypes) => {
    const year = moment(this.thisMoment())[this.getFormatter(momentType).yearFormatter]();
    // moment().get("month") is zero base
    const month = moment(this.thisMoment())[this.getFormatter(momentType).monthFormatter]() + 1;
    return moment(year + '/' + month + '/1', this.getFormatter(momentType).dateFormatter).unix() * 1000;
  };

  /**
   * get first day of next month
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date we need the next month in timestamp
   * @returns   {number} first day of next month in timestamp format
   */
  getNextMonth = (date: string, momentType: MomentTypes) => {
    let year = moment(date)[this.getFormatter(momentType).yearFormatter]();
    let month = moment(date)[this.getFormatter(momentType).monthFormatter]() + 2;
    if (month > 12) {
      month = 1;
      year++;
    }
    return moment(year + '/' + month + '/1', this.getFormatter(momentType).dateFormatter).unix() * 1000;
  };

  /**
   * get first day of prev month
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - the date we need the prev month in timestamp
   * @returns   {number} first day of prev month in timestamp format
   */
  getPrevMonth = (date: string, momentType: MomentTypes) => {
    let year = moment(date)[this.getFormatter(momentType).yearFormatter]();
    let month = moment(date)[this.getFormatter(momentType).monthFormatter]();
    if (month < 1) {
      month = 12;
      year--;
    }
    return moment(year + '/' + month + '/1', this.getFormatter(momentType).dateFormatter).unix() * 1000;
  };

  /**
   * get nights between 2 calendar dates
   * @author    HotelianCom
   * @argument  {number} firstDate - initial date
   * @argument  {number} secondDate - final date
   * @returns   {number} nights between 2 dates
   */
  getNightsBetween2Dates = (firstDate: number, secondDate: number) =>
    Math.round((secondDate - firstDate) / 1000 / 60 / 60 / 24);

  /**
   * get total days of a month in day and timestamp
   * @author    HotelianCom
   * @argument  {string} momentType - moment_js | moment_jalali
   * @argument  {number} date - timestamp
   * @returns   {Object[]} [{ dayNumber: 1, timeStamp: 1619811000000 },...]
   */
  getDaysInMonth = (date: string | number, momentType: MomentTypes) => {
    if (momentType === 'moment_jalali') {
      // jalali
      return Array.from({ length: moment(date).jDaysInMonth() }, (_, i) => i + 1).map(el => {
        return {
          dayNumber: el,
          timeStamp:
            moment(
              moment(date).format('jYYYY') + '-' + moment(date).format('jMMM') + '-' + el,
              'jYYYY-jMMM-jDD'
            ).unix() * 1000,
        };
      });
    } else {
      // Gregorian
      return Array.from({ length: moment(date).daysInMonth() }, (_, i) => i + 1).map(el => {
        return {
          dayNumber: el,
          timeStamp:
            moment(moment(date).format('YYYY') + '-' + moment(date).format('MMM') + '-' + el, 'YYYY-MMM-DD').unix() *
            1000,
        };
      });
    }
  };
}
const Utils = new CalendarUtils();
export const days = Utils.days;
export const getDay = Utils.getDay;
export const getDays = Utils.getDays;
export const getDaysInMonth = Utils.getDaysInMonth;
export const getFirstMonthDay = Utils.getFirstMonthDay;
export const getFormatter = Utils.getFormatter;
export const getFullDate = Utils.getFullDate;
export const getFullMonth = Utils.getFullMonth;
export const getMonth = Utils.getMonth;
export const getNextMonth = Utils.getNextMonth;
export const getNightsBetween2Dates = Utils.getNightsBetween2Dates;
export const getPrevMonth = Utils.getPrevMonth;
export const getThisMonth = Utils.getThisMonth;
export const getWeekDay = Utils.getWeekDay;
export const oneDay = Utils.oneDay;
export const oneMonth = Utils.oneMonth;
export const thisMoment = Utils.thisMoment;
export default Utils;
