import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';
import GeneralLayout from 'layouts/GeneralLayout';

const ViewCorporateRegister = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-corporate-register" */ 'views/general/corporate/register')
);

const ViewCorporateRegisterVerification = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-corporate-register" */ 'views/general/corporate/verify')
);

const generalRoutes = {
  Layout: GeneralLayout,
  mainPrefix: 'general/*',
  routes: [
    { id: 'general/corporate/register', Component: ViewCorporateRegister, path: urls.general.corporate.register },
    {
      id: 'general/corporate/verify',
      Component: ViewCorporateRegisterVerification,
      path: urls.general.corporate.verify,
    },
  ],
};

export default generalRoutes;
