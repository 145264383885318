import currencies from 'constants/currencies';
import { currencyStorage } from '@hotelian/helpers/storageHelpers';

const getCurrency = () => {
  const storedCurrency = currencyStorage.get();
  if (Boolean(storedCurrency) && currencies.find(c => c.value === storedCurrency)) {
    return currencies.find(c => c.value === storedCurrency);
  } else {
    return currencies.find(c => c.default);
  }
};

export default getCurrency;
