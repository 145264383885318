import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const View404Error = lazyWithRetry(() => import(/* webpackChunkName: "views-error" */ 'views/error/Custom404'));
const View429Error = lazyWithRetry(() => import(/* webpackChunkName: "views-error-429" */ 'views/error/Custom429'));
const View403Error = lazyWithRetry(() => import(/* webpackChunkName: "views-error" */ 'views/error/Custom403'));
const View500Error = lazyWithRetry(() => import(/* webpackChunkName: "views-error" */ 'views/error/Custom500'));

const fallbackRoutes = {
  Layout: undefined,
  mainPrefix: urls.fallback.mainPrefix,
  routes: [
    { id: '403', Component: View403Error, path: urls.fallback.error403 },
    { id: '404', Component: View404Error, path: urls.fallback.error404 },
    { id: '429', Component: View429Error, path: urls.fallback.error429 },
    { id: '500', Component: View500Error, path: urls.fallback.error500 },
  ],
};

export default fallbackRoutes;
