import Gateway from '@hotelian/utils/models/common/Gateway';

export const stripeAdapter = (
  gateways: Gateway[] | Record<string, Gateway>,
  { stripeImageUrl }: { stripeImageUrl: string }
): Gateway[] => {
  if (!gateways) {
    return [];
  }

  if (Array.isArray(gateways)) {
    return gateways.map(gateway => {
      const transformedGateway = Gateway.create(gateway);
      if (gateway.name === 'stripe') {
        return {
          ...gateway,
          image: stripeImageUrl as unknown as Gateway['image'],
          name: gateway.name,
        };
      } else {
        return transformedGateway;
      }
    });
  } else {
    return stripeAdapter(Object.values(gateways), { stripeImageUrl });
  }
};

export const increaseBalanceGatewayAdapter = (gateways: Gateway[] | Record<string, Gateway>): Gateway[] => {
  if (!gateways) {
    return [];
  }
  if (Array.isArray(gateways)) {
    return gateways.filter(gateway => gateway.can_top_up);
  } else {
    return increaseBalanceGatewayAdapter(Object.values(gateways));
  }
};

export const reserveGatewayAdapter = (gateways: Gateway[] | Record<string, Gateway>): Gateway[] => {
  if (!gateways) {
    return [];
  }
  if (Array.isArray(gateways)) {
    return gateways.filter(gateway => gateway.can_pay_for_reservation);
  } else {
    return reserveGatewayAdapter(Object.values(gateways));
  }
};
