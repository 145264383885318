import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewInvoicesList = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-invoices-list" */ 'views/app/invoices/list')
);
const ViewInvoicesDetail = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-invoices-detail" */ 'views/app/invoices/details')
);

const invoiceRoutes = [
  {
    id: 'invoice/list',
    Component: ViewInvoicesList,
    path: urls.app.invoice.list,
  },
  {
    id: 'invoice/view',
    Component: ViewInvoicesDetail,
    path: urls.app.invoice.view,
  },
];

export default invoiceRoutes;
