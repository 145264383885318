import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  faUser,
  faUserPlus,
  faUserHeadset,
  faSearch,
  faClock,
  faReceipt,
  faFileAlt,
  faPhone,
  faUserAlt,
  faFile,
  faHome,
} from '@fortawesome/pro-light-svg-icons';

import { b2bGeneralPrefix, documentationUrl, gb2bWebsiteUrl } from '@hotelian/constants/defaultValues';
import { agencyEditPerm } from 'constants/permissions';
import urls from './urls';
import { isCorporate } from '../helpers';

export const desktopNavMenu = [
  { path: urls.app.view, title: <FormattedMessage id="all.search" /> },
  {
    path: urls.app.history.list,
    title: <FormattedMessage id="all.history" />,
  },
  {
    path: urls.app.invoice.list,
    title: <FormattedMessage id="all.invoices" />,
  },
  {
    path: urls.app.transaction.list,
    title: <FormattedMessage id="all.transaction" />,
  },
  {
    path: urls.app.support.list,
    title: <FormattedMessage id="all.support" />,
  },
];

export const corporateDesktopUserPopoverMenu = [
  {
    path: urls.app.profile.view,
    icon: <FontAwesomeIcon icon={faUser} />,
    title: <FormattedMessage id="all.profile" />,
  },
  {
    path: urls.app.support.list,
    icon: <FontAwesomeIcon icon={faUserHeadset} />,
    title: <FormattedMessage id="all.ticket" />,
  },
];

export const desktopUserPopoverMenu = [
  {
    path: urls.app.profile.view,
    icon: <FontAwesomeIcon icon={faUser} />,
    title: <FormattedMessage id="all.profile" />,
  },
  {
    path: urls.app.agency.addUser,
    icon: <FontAwesomeIcon icon={faUserPlus} />,
    title: <FormattedMessage id="all.add-user" />,
    perm: [agencyEditPerm],
  },
  {
    path: urls.app.support.list,
    icon: <FontAwesomeIcon icon={faUserHeadset} />,
    title: <FormattedMessage id="all.ticket" />,
  },
];

export const corporateMobileNavMenu = [
  {
    path: urls.app.profile.view,
    icon: <FontAwesomeIcon icon={faUser} />,
    title: <FormattedMessage id="all.profile" />,
  },
  {
    path: urls.app.view,
    icon: <FontAwesomeIcon icon={faSearch} />,
    title: <FormattedMessage id="all.search" />,
  },
  {
    path: urls.app.history.list,
    icon: <FontAwesomeIcon icon={faClock} />,
    title: <FormattedMessage id="all.history" />,
  },
  {
    path: urls.app.invoice.list,
    icon: <FontAwesomeIcon icon={faReceipt} />,
    title: <FormattedMessage id="all.invoices" />,
  },
  {
    path: urls.app.transaction.list,
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    title: <FormattedMessage id="all.transaction" />,
  },
  {
    path: urls.app.support.list,
    icon: <FontAwesomeIcon icon={faUserHeadset} />,
    title: <FormattedMessage id="all.support" />,
  },
];

export const mobileNavMenu = [
  {
    path: urls.app.profile.view,
    icon: <FontAwesomeIcon icon={faUser} />,
    title: <FormattedMessage id="all.profile" />,
  },
  {
    path: urls.app.view,
    icon: <FontAwesomeIcon icon={faSearch} />,
    title: <FormattedMessage id="all.search" />,
  },
  {
    path: urls.app.history.list,
    icon: <FontAwesomeIcon icon={faClock} />,
    title: <FormattedMessage id="all.history" />,
  },
  {
    path: urls.app.invoice.list,
    icon: <FontAwesomeIcon icon={faReceipt} />,
    title: <FormattedMessage id="all.invoices" />,
  },
  {
    path: urls.app.transaction.list,
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    title: <FormattedMessage id="all.transaction" />,
  },
  {
    path: urls.app.agency.addUser,
    icon: <FontAwesomeIcon icon={faUserPlus} />,
    title: <FormattedMessage id="all.add-user" />,
    perm: [agencyEditPerm],
  },
  {
    path: urls.app.support.list,
    icon: <FontAwesomeIcon icon={faUserHeadset} />,
    title: <FormattedMessage id="all.support" />,
  },
];

export const generalMobileNavMenu = [
  {
    path: isCorporate() ? urls.general.corporate.register : gb2bWebsiteUrl,
    title: <FormattedMessage id="all.home" />,
    normalLink: !isCorporate(),
    icon: <FontAwesomeIcon icon={faHome} />,
    shouldRender: true,
  },
  ,
  {
    path: `${gb2bWebsiteUrl}/contact-us`,
    icon: <FontAwesomeIcon icon={faPhone} />,
    title: <FormattedMessage id="all.contact-us" />,
    normalLink: true,
    shouldRender: !isCorporate(),
  },
  {
    path: `${gb2bWebsiteUrl}/registration`,
    icon: <FontAwesomeIcon icon={faUserAlt} />,
    title: <FormattedMessage id="all.registration" />,
    normalLink: true,
    shouldRender: !isCorporate(),
  },
  {
    path: documentationUrl,
    icon: <FontAwesomeIcon icon={faFile} />,
    title: <FormattedMessage id="all.documentation" />,
    blank: true,
    shouldRender: !isCorporate(),
  },
];

export const generalDesktopNavMenu = [
  {
    path: isCorporate() ? urls.general.corporate.register : gb2bWebsiteUrl,
    title: <FormattedMessage id="all.home" />,
    normalLink: !isCorporate(),
    shouldRender: true,
  },
  {
    path: `${gb2bWebsiteUrl}/contact-us`,
    title: <FormattedMessage id="all.contact-us" />,
    normalLink: true,
    shouldRender: !isCorporate(),
  },
  {
    path: `${gb2bWebsiteUrl}/registration`,
    title: <FormattedMessage id="all.registration" />,
    normalLink: true,
    shouldRender: !isCorporate(),
  },
  {
    path: documentationUrl,
    title: <FormattedMessage id="all.documentation" />,
    blank: true,
    shouldRender: !isCorporate(),
  },
];
