import Farsi from './fa_IR.json';
import English from './en_UK.json';
import Arabic from './ar_SA.json';

const locales = [
  {
    locale: 'en',
    default: true,
    path: './en_UK.json',
    flag: '/images/flags/United-Kingdom.png',
    title: 'English',
    direction: 'ltr',
    moment: 'moment_js',
    messages: English,
    google: 'en_US',
    searchKey: 'en english united kingdom',
  },
  {
    locale: 'ar',
    path: './ar_SA.json',
    flag: '/images/flags/Saudi-Arabia.png',
    title: 'العربية',
    direction: 'rtl',
    moment: 'moment_js',
    messages: Arabic,
    google: 'ar_SA',
    searchKey: 'ar arabic عربی العربی',
  },
  /* {
    locale: 'fa',
    path: './fa_IR.json',
    flag: '/images/flags/Iran.png',
    title: 'فارسی',
    direction: 'rtl',
    moment: 'moment_js',
    messages: Farsi,
    google: 'fa_IR',
    searchKey: 'fa farsi فارسی persian iran ایران',
  },*/
];
export default locales;
