import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import { LoadingContext } from '.';

const LoadingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('ALL_REQUESTS_RETRIED', () => setLoading(false));
  }, []);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {loading ? (
        <div className="app-loading">
          <LinearProgress color="secondary" />
        </div>
      ) : null}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
