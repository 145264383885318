import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';
import { canBookPerm } from 'constants/permissions';

const ViewPassengers = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-passengers" */ 'views/app/hotel/reserve/passengers')
);
const ViewConfirm = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-confirm" */ 'views/app/hotel/reserve/confirm')
);
const ViewRedirect = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-redirect" */ 'views/app/hotel/reserve/redirect')
);

const reserveRoutes = [
  {
    id: 'hotel/reserve/passengers',
    Component: ViewPassengers,
    path: urls.app.hotel.reserve.passengers,
    perms: [canBookPerm],
  },
  { id: 'hotel/reserve/confirm', Component: ViewConfirm, path: urls.app.hotel.reserve.confirm, perms: [canBookPerm] },
  { id: 'hotel/reserve/redirect', Component: ViewRedirect, path: urls.app.hotel.reserve.redirect },
];

export default reserveRoutes;
