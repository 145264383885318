import { Navigate } from 'react-router-dom';

import { usePermission } from 'helpers/permission';
import urls from 'constants/urls';

const ProtectedRoute = ({ perms, children }) => {
  const permission = usePermission(perms);

  return permission ? children : <Navigate to={urls.fallback.error403} replace={true} />;
};

export default ProtectedRoute;
