import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import cookies from 'js-cookie';
import { useQueryClient } from 'react-query';
import { FormikHelpers } from 'formik';

import { AuthContext, CurrencyContext, LanguageContext, LoadingContext } from 'contexts';
import useReactRouter from 'utils/hooks/useReactRouter';
import api from '@hotelian/constants/api';
import { b2bPrefix, cookiesExpireTime } from '@hotelian/constants/defaultValues';
import { getToken, removeToken, setToken } from 'utils';
import IncreaseBalanceDialog from 'components/IncreaseBalanceDialog';
import usePageVisibility from '@hotelian/hooks/usePageVisibility';
import { accessTokenStorage, redirectUrlStorage } from '@hotelian/helpers/storageHelpers';
import { isFunction } from '@hotelian/utils';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import { Api, IRequestOptions } from '@hotelian/utils/ApiHandler/ApiInstance';
import ExceptionOf422 from 'utils/Facades/ErrorHandlers/defaultExceptions/ExceptionOf422';
import BackendErrorExtractor from '@hotelian/utils/ErrorHandler/BackendErrorExtractor';
import { Children } from '@hotelian/config/types';
import { useBroadcastContext } from 'utils/hooks/contextHooks';
import usePrevious from '@hotelian/hooks/usePrevious';
import urls from '../constants/urls';
import { isCorporate } from 'helpers';

interface IAuthContextProps {
  children: Children;
}

const signInRequestOptions: (
  setFieldError: FormikHelpers<any>['setFieldError']
) => IRequestOptions = setFieldError => ({
  errorOptions: {
    422: error => ExceptionOf422(error, { setFieldError }),
    404: error => {
      const { message, code } = BackendErrorExtractor.handle(error);
      if (code === 1201701 && message) {
        window.openSnackbar(message, 'error');
      }
    },
  },
});

const AuthContextProvider: React.FC<IAuthContextProps> = props => {
  const { navigate, location } = useReactRouter();
  const queryClient = useQueryClient();
  const { pathname, search } = location;
  const { locale } = useContext(LanguageContext);

  const { setLoading } = useContext(LoadingContext);
  const { changeCurrency } = useContext(CurrencyContext);

  const { observer } = useBroadcastContext();

  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  // isLoggedIn state is true when access token is available in local storage
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  // Increase balance dialog status
  const [openIncreaseBalance, setOpenIncreaseBalance] = useState(false);

  const isVisible = usePageVisibility();
  const isCorporateActive = isCorporate();
  const prevLocale = usePrevious(locale);

  // Is url starts with b2bPrefix
  /*const needAuthRoute =
    location.pathname.split('/').slice(0, 2).join('/') !== urls.user.login.split('/').slice(0, 3).join('/');*/
  const needAuthRoute =
    !location.pathname.startsWith(urls.user.login.split('/').slice(0, 3).join('/')) &&
    !location.pathname.startsWith(urls.general.corporate.register.split('/').slice(0, 2).join('/'));

  const handleRedirect = () => {
    const redirectUrl = redirectUrlStorage.get();
    if (redirectUrl) {
      redirectUrlStorage.remove();
      navigate(redirectUrl?.url + redirectUrl?.params ?? '');
    }
  };

  const setRedirectUrl = () => {
    if (needAuthRoute && pathname !== b2bPrefix) {
      redirectUrlStorage.set({ url: pathname, params: search });
    }
  };

  // we use this cookie in b2b site to check if we can show iran office details in contact us page
  // AAA is referencing to iran :)
  const setUserCountryCode = (data: any) => {
    const countryCode = data?.agency?.country_code;
    if (countryCode) {
      cookies.set('B2B_AGENCY_COUNTRY_CODE', countryCode === 'IR' ? 'AAA' : countryCode, {
        expires: cookiesExpireTime,
      });
    }
  };

  const signin = async (
    values: any,
    setFieldError: FormikHelpers<any>['setFieldError'],
    { goToHome = true, onCatch = (_err: any) => {}, redirectToLastPlace = false } = {}
  ) => {
    const redirectUrl = redirectUrlStorage.get();

    const { email, username, ...otherValues } = values;

    setLoading(true);
    let response;
    try {
      if (isCorporateActive) {
        response = await ResponseHandler.freeHandling(
          Api.call(
            { url: api.b2b.user.corporateLogin, method: 'post', data: { email, ...otherValues } },
            signInRequestOptions(setFieldError)
          )
        );
      } else {
        response = await ResponseHandler.freeHandling(
          Api.call(
            { url: api.b2b.user.login, method: 'post', data: { username, ...otherValues } },
            signInRequestOptions(setFieldError)
          )
        );
      }

      setUser(response.user);

      changeCurrency(response?.user?.agency?.currency?.key);

      await setToken(response);

      setIsLoggedIn(true);
      observer.fire({ name: 'TOKEN_REFRESHED', payload: response });

      if (redirectUrl && redirectToLastPlace) {
        await handleRedirect();
      } else if (goToHome) {
        navigate(`${b2bPrefix}`, { replace: true });
      }
      setOpenSessionExpiredModal(false);

      return Promise.resolve(response);
    } catch (err) {
      if (isFunction(onCatch)) {
        onCatch(err);
      }
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  // Get user details from backend and update user state
  const getUserDetail = async () => {
    setIsUserLoading(true);
    try {
      const res = await ResponseHandler.freeHandling(
        Api.call(
          { url: api.b2b.user.userDetail },
          {
            errorOptions: {
              requestError: () => {
                removeToken();
                setIsLoggedIn(false);
                setUser(null);
                setRedirectUrl();
                navigate(urls.user.login);
              },
              401: (error, helpers) => {
                if (!pathname.includes(urls.user.login)) {
                  helpers.defaultHandler();
                }
              },
            },
          }
        )
      );

      // Set user state
      setUser(res);

      setUserCountryCode(res);

      return res;
    } catch (e) {
      return null;
    } finally {
      setIsUserLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);

    try {
      if (accessTokenStorage.get()) {
        await ResponseHandler.freeHandling(Api.call({ url: api.b2b.user.logout, method: 'delete' }));
      }

      setToken({ token: null });
      setUser(null);
      setIsLoggedIn(false);
      await redirectUrlStorage.remove();
      await queryClient.removeQueries();
      observer.fire({ name: 'LOGOUT_USER' });
      navigate(urls.user.login, { replace: true });
    } catch (_err) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = (url: string) => {
    setUser((prevState: any) => ({ ...prevState, profile_pic: url }));
  };

  const updateAgencyProfile = (url: string) => {
    setUser((prevState: any) => ({
      ...prevState,
      agency: { ...prevState.agency, logo: url },
    }));
  };

  const toggleIncreaseBalance = () => {
    setOpenIncreaseBalance(prevState => !prevState);
  };

  useEffect(() => {
    if (needAuthRoute && getToken() && !user && !openSessionExpiredModal) {
      getUserDetail().then(response => {
        changeCurrency(response?.agency?.currency?.key);
      });
    }
  }, [needAuthRoute, openSessionExpiredModal]);

  useEffect(() => {
    // refetch user detail in case of language change
    if (needAuthRoute && getToken() && !openSessionExpiredModal && locale !== prevLocale && prevLocale) {
      getUserDetail();
    }
  }, [locale]);

  useEffect(() => {
    // Set isLoggedIn state true if access token exists
    if (getToken()) {
      setIsLoggedIn(true);
    }
    // If access token does not exist in localStorage, user must navigate to protected route
    if (needAuthRoute && !Boolean(getToken())) {
      navigate(urls.user.login);
    }
  }, []);

  useEffect(() => {
    if (!observer.canHandleCrossTab && isVisible) {
      const token = getToken();
      if (token && (pathname === urls.user.login || openSessionExpiredModal)) {
        setToken({ token });
        setIsLoggedIn(true);
        getUserDetail().then(() => {
          if (openSessionExpiredModal) {
            setOpenSessionExpiredModal(false);
          } else {
            const redirectUrl = redirectUrlStorage.get();
            if (redirectUrl) {
              handleRedirect();
            } else {
              navigate('/');
            }
          }
        });
      } else {
        if (needAuthRoute && !token) {
          setOpenSessionExpiredModal(true);
          setIsLoggedIn(false);
        }
      }
    }
  }, [isVisible]);

  useEffect(() => {
    observer.subscribe({
      name: 'TOKEN_EXPIRED',
      callback: () => {
        setRedirectUrl();
        setToken({ token: null });
        if (user) {
          setIsLoggedIn(false);
          if (needAuthRoute) {
            setOpenSessionExpiredModal(true);
          }
        } else {
          navigate(urls.user.login, { replace: true });
        }
      },
    });
    observer.subscribe({
      name: 'TOKEN_REFRESHED',
      callback: _payload => {
        setIsLoggedIn(true);
        setToken({ token: getToken() });
        const redirectUrl = redirectUrlStorage.get();
        if (openSessionExpiredModal) {
          setOpenSessionExpiredModal(false);
        } else if (redirectUrl) {
          handleRedirect();
        } else if (!needAuthRoute) {
          navigate('/');
        }
      },
    });

    // ps:  there is a difference between logging out with an observer that can
    // handleCrossTab and one can not; if observer can handle cross tab feat,
    // user will be navigated to /user/login page, and if it does not support,
    // since we can not communicate with other tabs, UserIdleModal will show up
    // on tabs other than the tab user logged out on it.
    observer.subscribe({
      name: 'LOGOUT_USER',
      callback: ({ tabId }) => {
        if (tabId !== observer.tabId) {
          if (needAuthRoute) {
            navigate(urls.user.login);
          }
          setIsLoggedIn(false);
          setToken({ token: null });
        }
      },
    });
  }, [needAuthRoute, openSessionExpiredModal, user]);

  /*if (needAuthRoute && !Boolean(getToken()) && !openSessionExpiredModal) {
    return <Navigate replace to="/" />;
  }*/

  const NavigateToLoginPage = () => {
    if (getToken()) {
      removeToken();
    }
    setIsLoggedIn(false);
    setIsUserLoading(false);
    setRedirectUrl();
    return <Navigate replace to={urls.user.login} />;
  };

  useEffect(() => {
    if (needAuthRoute && !getToken()) {
      setRedirectUrl();
      navigate(urls.user.login);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        signin,
        setUser,
        updateUserProfile,
        updateAgencyProfile,
        getUserDetail,
        logout,
        toggleIncreaseBalance,
        openSessionExpiredModal,
        setOpenSessionExpiredModal,
        setIsLoggedIn,
      }}
    >
      {needAuthRoute ? (
        <Fragment>
          {user || openSessionExpiredModal ? (
            <Fragment>
              <IncreaseBalanceDialog open={openIncreaseBalance} toggle={toggleIncreaseBalance} />
              {props.children}
            </Fragment>
          ) : isUserLoading ? (
            <div className="loading" />
          ) : (
            <NavigateToLoginPage />
          )}
        </Fragment>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
