export default class Gateway {
  image!: string;
  name!: string;
  can_pay_for_reservation!: boolean;
  can_top_up!: boolean;
  fee_percentage!: number;

  static create(values: Gateway) {
    const instance = new Gateway();
    if (values) {
      instance.name = values.name;
      instance.image = values.image;
      instance.can_pay_for_reservation = values.can_pay_for_reservation;
      instance.can_top_up = values.can_top_up;
      instance.fee_percentage = values.fee_percentage;
    }
    return instance;
  }
}
