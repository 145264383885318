import { agencyEditPerm } from 'constants/permissions';
import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const AddUser = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-agency-add-user" */ 'views/app/agency/add-user')
);
const EditUser = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-agency-edit-user" */ 'views/app/agency/edit-user')
);

const agencyRoutes = [
  { id: 'agency/add-user', Component: AddUser, path: urls.app.agency.addUser, perms: [agencyEditPerm] },
  { id: 'agency/edit-user', Component: EditUser, path: urls.app.agency.editUser, perms: [agencyEditPerm] },
];

export default agencyRoutes;
