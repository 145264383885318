export default class ListHandler<T> {
  items: T[] = [];
  _links?:
    | {
        self: { href: string };
        first: { href: string };
        last: { href: string };
      }
    | undefined;
  _meta?:
    | {
        totalCount: number;
        pageCount: number;
        currentPage: number;
        perPage: number;
      }
    | undefined;

  constructor(list: ListHandler<T>) {
    this.items = list.items;
    this._links = list._links;
    this._meta = list._meta;
  }

  static handle<U>(list: ListHandler<U>, Model: { create: (values: any) => U }): ListHandler<U> {
    if (!Model.create) throw new Error('Entities that are using ListHandler facade must have static create method');
    return new ListHandler<U>({ ...list, items: list.items.map(item => Model.create(item)) });
  }

  static handleSimpleArray<U>(list: U[], Model: { create: (values: any) => U }) {
    if (!Model.create) throw new Error('Entities that are using ListHandler facade must have static create method');
    return list.map(item => Model.create(item));
  }
}
