import { useQuery } from 'react-query';
import General from '@hotelian/utils/models/General/index';
import { AppUseQueryOptions } from '@hotelian/config/interfaces';

export default class GeneralQueries {
  static keys = {
    all: ['general-queries'] as const,
    baseData: (locale: string) => [...this.keys.all, locale],
  };

  static useBaseData(locale: string, { queryOptions }: { queryOptions?: AppUseQueryOptions } = {}) {
    return useQuery(this.keys.baseData(locale), General.getBaseData, queryOptions);
  }
}
