import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserDetailsCmp from 'components/UserDetailsCmp';
import { corporateMobileNavMenu, mobileNavMenu } from 'constants/navMenu';
import { WithPermission } from 'helpers/permission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { AuthContext } from 'contexts';
import { faCoins, faPowerOff } from '@fortawesome/pro-light-svg-icons';
import { isCorporate } from 'helpers';

const MobileAppDrawer = ({ open, setOpen }) => {
  const { logout, toggleIncreaseBalance } = React.useContext(AuthContext);
  const { pathname } = useLocation();

  const navMenuItems = isCorporate() ? corporateMobileNavMenu : mobileNavMenu;
  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
      >
        <div className="b2b-app-mobile-drawer">
          <div className="b2b-app-mobile-drawer-user-detail text-white">
            <UserDetailsCmp />
          </div>
          <div className="b2b-app-mobile-drawer-menu-wrapper text-white">
            <nav>
              <ul className="list-unstyled p-0 m-0">
                {navMenuItems.map((el, i) => {
                  const isActive = el.path === pathname;
                  return (
                    <WithPermission key={i} perms={[...(el.perm ?? [])]}>
                      <li>
                        <Link to={el.path}>
                          <Button
                            onClick={() => {
                              setOpen(false);
                            }}
                            size="large"
                            className="w-100 d-block text-white px-3"
                            color={isActive ? 'secondary' : undefined}
                            variant={isActive ? 'contained' : undefined}
                          >
                            <div className="w-100 d-flex align-items-center">
                              <div className="mr-3">{el.icon}</div>
                              <div>{el.title}</div>
                            </div>
                          </Button>
                        </Link>
                      </li>
                    </WithPermission>
                  );
                })}
                <li>
                  <Button
                    onClick={() => {
                      toggleIncreaseBalance();
                      setOpen(false);
                    }}
                    size="large"
                    className="w-100 d-block text-white px-3"
                  >
                    <div className="w-100 d-flex align-items-center">
                      <div className="mr-3">
                        <FontAwesomeIcon icon={faCoins} />
                      </div>
                      <div>
                        <FormattedMessage id="all.increase-balance" />
                      </div>
                    </div>
                  </Button>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      logout();
                    }}
                    size="large"
                    className="w-100 d-block px-3"
                    color="secondary"
                  >
                    <div className="w-100 d-flex align-items-center">
                      <div className="mr-3">
                        <FontAwesomeIcon icon={faPowerOff} />
                      </div>
                      <div>
                        <FormattedMessage id="all.logout" />
                      </div>
                    </div>
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default MobileAppDrawer;
