import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewPaymentVerification = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-payment-verification-view" */ 'views/app/payment/payment-verification')
);

const paymentRoutes = [
  { id: 'payment/verification', Component: ViewPaymentVerification, path: urls.app.payment.verification },
];

export default paymentRoutes;
