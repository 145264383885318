import React, { useEffect, useContext, useState } from 'react';

import { AuthContext, DraftContext } from '.';
import useDraftContext from '@hotelian/hooks/useDraftContext';
import api from '@hotelian/constants/api';
import prefixesData from '@hotelian/constants/prefixesData';
import ResponseHandler from '@hotelian/utils/Facades/ResponseHandler';
import { Api } from '@hotelian/utils/ApiHandler/ApiInstance';
import { useLanguageContext } from '../utils/hooks/contextHooks';

const DraftContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { locale } = useLanguageContext();

  const [userCountryCode, setUserCountryCode] = useState(null);

  const props = useDraftContext(true, user, { withNationality: true, language: locale ?? undefined });

  const getUserPreferences = async () => {
    try {
      const response = await ResponseHandler.freeHandling(Api.call({ url: api.general.decideUserStatus }));

      const countryCode = response?.country_code;

      if (!countryCode) {
        localStorage.setItem('B2B_USER_COUNTRY_CODE', prefixesData.find(el => el.default).isoCode);
      } else {
        localStorage.setItem('B2B_USER_COUNTRY_CODE', countryCode);
      }
      setUserCountryCode(localStorage.getItem('B2B_USER_COUNTRY_CODE'));
      return response;
    } catch (_e) {
      return null;
    }
  };

  const changeUserCountryCode = code => {
    localStorage.setItem('B2B_USER_COUNTRY_CODE', code);
    setUserCountryCode(code);
  };

  useEffect(() => {
    if (!localStorage.getItem('B2B_USER_COUNTRY_CODE')) {
      getUserPreferences();
    } else {
      setUserCountryCode(localStorage.getItem('B2B_USER_COUNTRY_CODE'));
    }
  }, []);

  return (
    <DraftContext.Provider
      value={{
        ...props,
        getUserPreferences,
        userCountryCode,
        setUserCountryCode,
        changeUserCountryCode,
      }}
    >
      {children}
    </DraftContext.Provider>
  );
};

export default DraftContextProvider;
