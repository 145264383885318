import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

export default class Request {
  requestInstance: AxiosInstance = axios;
  baseURL: string = '';
  requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  failedRequestQueue: {
    resolve: (value: { resolve: (value: PromiseLike<unknown> | unknown) => void; token: string }) => void;
    reject: (reason?: any) => void;
  }[] = [];

  static create(options?: { headers: AxiosRequestHeaders; baseURL: string }) {
    const request = new Request();
    request.requestInstance = axios.create({
      baseURL: options?.baseURL,
    });
    if (options) {
      request.setHeaders(options.headers);
      request.setBaseUrl(options.baseURL);
      // request.requestConfig.headers = {...request.requestConfig.headers,...options.headers}
      // request.baseURL = options.baseURL
    }
    return request;
  }

  setBaseUrl(baseURL: string) {
    this.baseURL = baseURL;
    this.requestConfig = {
      ...this.requestConfig,
      baseURL,
    };
    return this;
  }

  setHeaders(requestHeaders: AxiosRequestHeaders) {
    this.requestConfig = { ...this.requestConfig, headers: { ...this.requestConfig.headers, ...requestHeaders } };
    if (!this.requestInstance) throw new Error('No Request instance founded');
    this.requestInstance.interceptors.request.use(config => {
      return { ...config, ...this.requestConfig, headers: { ...config.headers, ...this.requestConfig.headers } };
    });
    return this;
  }

  setLanguage(l: string) {
    this.requestConfig = {
      ...this.requestConfig,
      headers: { ...this.requestConfig.headers, 'X-Accepted-Language': l },
    };
    return this;
  }

  setCurrency(currency: string) {
    this.requestConfig = {
      ...this.requestConfig,
      headers: { ...this.requestConfig.headers, 'X-Accepted-Currency': currency },
    };
    return this;
  }

  setAccessToken(token: string | null, options: { tokenType: string } = { tokenType: 'Bearer' }) {
    this.requestConfig = {
      ...this.requestConfig,
      headers: { ...this.requestConfig.headers, Authorization: `${options.tokenType} ${token}` },
    };
    if (!token) {
      delete this?.requestConfig?.headers?.['Authorization'];
    }
    if (!this.requestInstance) throw new Error('No Request instance founded');
    this.requestInstance.interceptors.request.use(config => {
      return { ...config, ...this.requestConfig, headers: { ...config.headers, ...this.requestConfig.headers } };
    });
    return this;
  }

  resetHeaders = () => {
    this.requestConfig = { ...this.requestConfig, headers: defaultHeaders };
    return this;
  };

  setResponseInterceptor(responseErrorHandler: (err: AxiosError) => void) {
    if (!this.requestInstance) throw new Error('No Request instance founded');
    this.requestInstance.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return responseErrorHandler(err);
      }
    );
  }

  retryFailedRequests(token: string | null) {
    let retriedRequests = 0;
    let queueLength = this.failedRequestQueue.length;
    const allRequestsRetried = new CustomEvent('ALL_REQUESTS_RETRIED');
    if (token) {
      // const promises = this.failedRequestQueue.map(el => el.resolve(token));
      if (this.failedRequestQueue.length > 0) {
        this.failedRequestQueue.forEach(prom => {
          new Promise((resolve, reject) => {
            prom.resolve({ token, resolve });
          }).then(() => {
            retriedRequests++;
            if (queueLength === retriedRequests) {
              window.dispatchEvent(allRequestsRetried);
            }
          });
        });
      } else {
        window.dispatchEvent(allRequestsRetried);
      }
    }

    this.failedRequestQueue = [];
  }
}
