import Footer from 'components/Footer';
import React from 'react';
import { b2bGeneralPrefix } from '@hotelian/constants/defaultValues';
import MobileTopbar from './common/MobileTopbar';
import MobileGeneralDrawer from './common/MobileGeneralDrawer';
const MobileGeneralLayout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="b2b-app-layout">
      <MobileGeneralDrawer open={open} setOpen={setOpen} />
      <MobileTopbar setOpen={setOpen} logoUrl={`${b2bGeneralPrefix}`} withLanguageSwitcher />
      <main className="content-container">{children}</main>
      <Footer mobileMode />
    </div>
  );
};

export default MobileGeneralLayout;
