import { b2bGeneralPrefix, b2bPrefix } from '@hotelian/constants/defaultValues';

const urls = {
  app: {
    view: `${b2bPrefix}`,
    mainPrefix: 'app/*',
    history: {
      view: `${b2bPrefix}/history/:id`,
      list: `${b2bPrefix}/history`,
    },
    profile: {
      view: `${b2bPrefix}/profile`,
    },
    support: {
      list: `${b2bPrefix}/support`,
      reply: `${b2bPrefix}/support/reply/:id`,
      new: `${b2bPrefix}/support/new`,
    },
    agency: {
      addUser: `${b2bPrefix}/agency/add-user`,
      editUser: `${b2bPrefix}/agency/edit-user/:id`,
    },
    invoice: {
      list: `${b2bPrefix}/invoices`,
      view: `${b2bPrefix}/invoices/:id`,
    },
    transaction: {
      list: `${b2bPrefix}/transaction`,
    },
    services: {
      list: `${b2bPrefix}/services/`,
    },
    hotel: {
      search: `${b2bPrefix}/hotel/search/:id`,
      view: `${b2bPrefix}/hotel/:id`,
      reserve: {
        passengers: `${b2bPrefix}/hotel/reserve/passengers/:oid/:sid`,
        confirm: `${b2bPrefix}/hotel/reserve/confirm/:oid/:sid/:rid`,
        redirect: `${b2bPrefix}/hotel/reserve/redirect`,
      },
    },
    payment: {
      verification: `${b2bPrefix}/payment/verification`,
    },
  },
  general: {
    mainPrefix: 'general/*',
    corporate: {
      register: `${b2bGeneralPrefix}/corporate/register`,
      verify: `${b2bGeneralPrefix}/corporate/verify`,
    },
  },
  user: {
    mainPrefix: `${b2bPrefix}/user/*`,
    login: `${b2bPrefix}/user/login`,
    personate: `${b2bPrefix}/user/personate`,
  },
  fallback: {
    mainPrefix: `${b2bPrefix}/*`,
    error403: `${b2bPrefix}/403`,
    error404: `${b2bPrefix}/404`,
    error429: `${b2bPrefix}/429`,
    error500: `${b2bPrefix}/500`,
  },
};

export default urls;
