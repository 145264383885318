import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import LoginForm from 'containers/user/common/LoginForm';
import { AuthContext } from 'contexts';
import { captchaExpiresAfter } from '@hotelian/constants/defaultValues';
import usePageVisibility from '@hotelian/hooks/usePageVisibility';
import { useBroadcastContext } from 'utils/hooks/contextHooks';

const UserIdleModal = ({ open }) => {
  const { user, setOpenSessionExpiredModal } = useContext(AuthContext);
  const { observer } = useBroadcastContext();

  const isVisible = usePageVisibility();

  const [captchaExpired, setCaptchaExpired] = useState(false);
  const [shouldRequestNewCaptcha, setShouldRequestNewCaptcha] = useState(false);
  const [timeout, setTO] = useState(0);

  const refreshCaptchaTimeout = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setTO(
      setTimeout(() => {
        setCaptchaExpired(true);
      }, captchaExpiresAfter)
    );
  };

  useEffect(() => {
    if (open) {
      refreshCaptchaTimeout();
    }
  }, [open]);

  useEffect(() => {
    if (isVisible && captchaExpired) {
      setShouldRequestNewCaptcha(true);
      setTimeout(() => {
        setShouldRequestNewCaptcha(false);
      }, 200);
      refreshCaptchaTimeout();
      setCaptchaExpired(false);
    }
  }, [open, captchaExpired, isVisible]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className="h6">
          <FormattedMessage id="login.user-idle-modal.title" />
        </div>
      </DialogTitle>
      <DialogContent>
        <LoginForm
          isModal={true}
          initialValues={{ username: user?.username, email: user?.email }}
          afterLogin={_res => {
            setOpenSessionExpiredModal(false);
            observer.fire({ name: 'RETRY_FAILED_REQUESTS' }, { crossTab: false });
          }}
          newCaptcha={shouldRequestNewCaptcha}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UserIdleModal;
