import React, { useState, useContext, Fragment } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { isMultiLanguage } from 'constants/default-values';
import { LanguageContext, LoadingContext } from 'contexts';
import locales from 'locales';

const LanguageSwitcher = ({ smallButton, ...props }) => {
  const { loading } = useContext(LoadingContext);
  const { localeObj, changeLang } = useContext(LanguageContext);

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(prevState => !prevState);
  };

  const getBtnContent = l => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <img src={l.flag} alt={l.title} style={{ height: 15 }} className="w-auto mr-2" />
        <span>{l.title}</span>
      </div>
    );
  };
  if (!isMultiLanguage) {
    return <Fragment />;
  }
  return (
    <Fragment>
      <Dialog open={openModal} onClose={toggleModal} fullWidth maxWidth="sm">
        <DialogTitle className="bg-foreground-gray text-center">
          <FormattedMessage id="all.language" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {locales.map((el, i) => {
              const isSelected = localeObj.locale === el.locale;
              return (
                <Grid item xs={6} sm={4} key={i}>
                  <Button
                    color="primary"
                    className={`w-100 d-block ${isSelected ? 'bg--light-primary' : ''}`}
                    onClick={() => {
                      if (isSelected) {
                        toggleModal();
                        return;
                      }
                      changeLang(el.locale, toggleModal);
                    }}
                    disabled={loading}
                  >
                    {getBtnContent(el)}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
      <div>
        {smallButton ? (
          <Button onClick={toggleModal} className="text-white p-0 overflow-hidden">
            <img className="flags-images" src={localeObj.flag} alt={localeObj.title} />
          </Button>
        ) : (
          <Button onClick={toggleModal} {...props}>
            {getBtnContent(localeObj)}
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default LanguageSwitcher;
