import React, { useState, useEffect } from 'react';

import { UIContext } from '.';
import useReactRouter from 'utils/hooks/useReactRouter';
import NotFoundDialog from 'components/NotFoundDialog';
import Error403Dialog from 'components/dialogs/Error403Dialog';
import { useBroadcastContext } from '../utils/hooks/contextHooks';
import { browserTabIdStorage } from '@hotelian/helpers/storageHelpers';

const UIContextProvider = ({ children }) => {
  const router = useReactRouter();

  const { observer } = useBroadcastContext();

  const [headerAppended, setHeaderAppended] = useState(null);
  const [footerHolder, setFooterHolder] = useState(0);
  const [is404DialogVisible, setIs404DialogVisible] = useState(false);
  const [is403DialogVisible, setIs403DialogVisible] = useState(false);
  const [messages, setMessages] = useState({});

  // to identify current tab
  const [browserTabId] = useState(Math.random());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.location.pathname]);

  useEffect(() => {
    observer.setTabId(browserTabId.toString());
  }, [browserTabId]);

  useEffect(() => {
    browserTabIdStorage.set(browserTabId.toString());

    observer.subscribe({
      name: 'BACKEND_ITEM_NOT_FOUND',
      callback: () => {
        setIs404DialogVisible(true);
      },
    });
  }, []);

  const handleOpen403Dialog = (open, fnMessages) => {
    if (open) {
      setMessages(fnMessages);
      setIs403DialogVisible(true);
    }
  };

  return (
    <UIContext.Provider
      value={{
        headerAppended,
        setHeaderAppended,
        footerHolder,
        setFooterHolder,
        tabId: browserTabId,
        handleOpen403Dialog,
      }}
    >
      <NotFoundDialog setOpen={setIs404DialogVisible} open={is404DialogVisible} />
      <Error403Dialog
        setOpen={setIs403DialogVisible}
        open={is403DialogVisible}
        messages={messages}
        setMessages={setMessages}
      />
      {children}
    </UIContext.Provider>
  );
};

export default UIContextProvider;
