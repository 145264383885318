import React from 'react';
import { ThemeProvider as MUIThemeProvider, StylesProvider, jssPreset, createTheme } from '@material-ui/core/styles';
import Snackbar from '@hotelian/components/Snackbar';
import { create } from 'jss';
import rtl from 'jss-rtl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IntlProvider } from 'react-intl';

// @material-ui main configuration
const MUiMainTheme = Object.freeze({
  palette: {
    primary: {
      main: '#00247D',
    },
    secondary: {
      main: '#CF142B',
    },
    labelGrey: {
      main: '#909090',
    },
    background: {
      default: '#fff',
    },
    lightBlue: {
      main: '#008ceb',
      default: '#008ceb',
    },
  },
  typography: {
    fontFamily: 'var(--main-font-family), sans-serif !important',
    caption: {
      color: 'var(--gray-color)',
      fontSize: 13,
    },
  },
  props: {
    MuiTypography: {},
  },
  shape: { borderRadius: 5 },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '0.45vw',
          height: '6px',
          transition: 'all 0.4s',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#999',
          borderRadius: '5px',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: '#666',
        },
      },
    },
  },
});

// @material-ui ltr configuration
export const ltrTheme = createTheme({ ...MUiMainTheme });

// @material-ui rtl configuration
export const rtlTheme = createTheme({ ...MUiMainTheme, direction: 'rtl' });

// create jss configuration
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

/**
 * @author HotelianCom
 * @param {Object} props {direction: @material-ui theme direction, messages: application messages, locale: application locales, children}
 * @returns {ReactElement}
 */

const ThemeProvider = ({ direction, messages, locale, children }) => {
  const getTheme = () => {
    if (direction === 'ltr') {
      return ltrTheme;
    } else {
      return rtlTheme;
    }
  };
  return (
    <StylesProvider jss={jss}>
      <MUIThemeProvider theme={getTheme()}>
        <CssBaseline />
        <IntlProvider messages={messages} locale={locale}>
          <Snackbar />
          {children}
        </IntlProvider>
      </MUIThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
