import { lazy } from 'react';
import { createBrowserHistory } from 'history';

import { accessTokenStorage } from '@hotelian/helpers/storageHelpers';
import { thisMoment } from '@hotelian/utils/CalendarUtils';
import { Instance } from './AxiosInstance';
import { isString } from '@hotelian/utils';
import { URLParamInjector } from '@hotelian/config/interfaces';

export const getToken = () => accessTokenStorage.get();

export const setToken = (res: any) => {
  if (res.token) {
    Instance.setAccessToken(res.token);

    accessTokenStorage.set(res.token, {
      // expires: res?.expires_at ? new Date(res?.expires_at) : new Date(thisMoment() + 90 * 24 * 60 * 60 * 1000),
      expires: new Date(thisMoment() + 90 * 24 * 60 * 60 * 1000),
      httpOnly: false,
      secure: isInProduction,
      sameSite: 'lax',
    });
  } else {
    Instance.setAccessToken(null);
    accessTokenStorage.remove();
  }
};

export const removeToken = () => {
  Instance.setAccessToken(null);
  accessTokenStorage.remove();
};

export const selectText = (node: any) => {
  node = document.getElementById(node);

  // @ts-ignore
  if (document.body.createTextRange) {
    // @ts-ignore
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
};

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    /*const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem('page-has-been-force-refreshed') || 'false'
    );*/

    return componentImport();
    /*try {
      const component = await componentImport();

      window.sessionStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.sessionStorage.setItem('page-has-been-force-refreshed', 'true');
        // return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }*/
  });

export const history = createBrowserHistory({ window });

export const isInProduction = Boolean(process.env.NODE_ENV === 'production');

export const urlParamInjector: URLParamInjector = (url, params, { queryParams } = { queryParams: [] }) => {
  if (isString(url)) {
    const hasQueryParams = queryParams?.length > 0;
    const urlSeparatedBySlash = url.split('/');
    let urlToReturn = `${url}`;
    if (params) {
      params.forEach(el => {
        if (urlSeparatedBySlash.findIndex(item => item === `:${el.name}`) === -1 && !isInProduction) {
          window.openSnackbar(`${url} does not include ${el.name} (urlParamInjector)`, 'error');
          return '';
        } else {
          urlToReturn = urlToReturn.replace(`:${el.name}`, String(el.value));
        }
      });
    }

    let queryParamsToReturn = '?';
    if (hasQueryParams) {
      queryParams.forEach(queryParam => {
        queryParamsToReturn = queryParamsToReturn.concat(
          queryParam.key,
          '=',
          queryParam.value.toString(),
          queryParamsToReturn.length > 0 ? '' : '&'
        );
      });
    }
    return hasQueryParams ? urlToReturn.concat(queryParamsToReturn) : urlToReturn;
  } else {
    console.error('url is not typeof string (urlParamInjector)');
    return '';
  }
};
