import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: 'rgba(25, 25, 25, 0.95)',
    padding: '0.5rem 0.75rem',
    boxShadow: theme.shadows[10],
  },
  customArrow: {
    fontSize: 12,
    color: 'rgba(25,25, 25, 0.95)',
    boxShadow: theme.shadows[10],
  },
}));

const CustomTooltip = ({ children, title, titleVariant }) => {
  const classes = useStyles();
  if (!title) {
    return children;
  }
  return (
    <Tooltip
      arrow
      interactive
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={
        <Typography className="text-white" variant={titleVariant ?? 'caption'}>
          {title}
        </Typography>
      }
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
