import { lazyWithRetry } from 'utils';
import urls from 'constants/urls';

const ViewHistoryList = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-history-list" */ 'views/app/history/list')
);
const ViewHistoryDetail = lazyWithRetry(() =>
  import(/* webpackChunkName: "views-history-detail" */ 'views/app/history/details')
);

const historyRoutes = [
  {
    id: 'history/list',
    Component: ViewHistoryList,
    path: urls.app.history.list,
  },
  {
    id: 'history/detail',
    Component: ViewHistoryDetail,
    path: urls.app.history.view,
  },
];

export default historyRoutes;
